// classes/PlatformManager.js
import Phaser from 'phaser';

export default class PlatformManager {
    constructor(scene) {
        this.scene = scene;
        
        // Create the platform group
        this.platformGroup = this.scene.add.group({
            removeCallback: (platform) => {
                this.platformPool.add(platform); // Recycle platform to pool
            }
        });

        // Create the platform pool (recycled platforms)
        this.platformPool = this.scene.add.group({
            removeCallback: (platform) => {
                this.platformGroup.add(platform); // Add platform back to the group
            }
        });
        
        // Initialize next platform spawn distance
        this.nextPlatformDistance = Phaser.Math.Between(
            this.scene.gameOptions.spawnRange[0], 
            this.scene.gameOptions.spawnRange[1]
        );
    }

    // Add a platform (either from pool or new)
    addPlatform(platformWidth, posX) {
        let platform;

        // If we have recycled platforms in the pool, reuse them
        if (this.platformPool.getLength()) {
            platform = this.platformPool.getFirst();
            platform.x = posX;
            platform.active = true;
            platform.visible = true;
            this.platformPool.remove(platform);
            platform.body.setVelocityX(this.scene.gameOptions.platformStartSpeed * -1 * this.scene.gameOptions.playerSpeed);
        } else {
            // Otherwise create a new platform
            platform = this.scene.physics.add.sprite(posX, this.scene.sys.game.config.height, "platform"); // Start off the screen
            platform.setImmovable(true);
            platform.setVelocityX(this.scene.gameOptions.platformStartSpeed * -1 * this.scene.gameOptions.playerSpeed);
            this.platformGroup.add(platform);
        }

        // Scale down the platform
        platform.setScale(0.5); // Scale down the platform by 50%

        // Adjust the width and height for the scaled-down platform
        platform.displayWidth = platformWidth * 0.5; // Adjust width for scaled-down platform
        platform.displayHeight = platform.displayHeight * 0.5; // Adjust height for scaled-down platform

        // Position platform above the last platform in the group
        const lastPlatform = this.platformGroup.getChildren().slice(-1)[0]; // Get the last platform added
        if (lastPlatform) {
            platform.y = lastPlatform.y - (this.scene.gameOptions.jumpForce * 0.4); // Position above the last platform by a certain height
        }

        // Position platform at the bottom of the canvas
        platform.y = this.scene.sys.game.config.height - platform.displayHeight / 5;

        // Calculate the next platform spawn distance
        this.nextPlatformDistance = Phaser.Math.Between(this.scene.gameOptions.spawnRange[0], this.scene.gameOptions.spawnRange[1]);
    }

    // Recycle platforms when they move off the screen
    recyclePlatforms() {
        this.platformGroup.getChildren().forEach((platform) => {
            if (platform.x < -platform.displayWidth / 2) {
                this.platformGroup.killAndHide(platform);
                this.platformGroup.remove(platform);
            }
        });
    }

    // Check if we need to add new platforms based on the distance
    addNewPlatforms() {
        let minDistance = this.scene.sys.game.config.width;
        
        // Calculate the minimum distance between the right edge of the screen and the last platform
        this.platformGroup.getChildren().forEach((platform) => {
            let platformDistance = this.scene.sys.game.config.width - platform.x - platform.displayWidth / 2;
            minDistance = Math.min(minDistance, platformDistance);
        });

        // If the minimum distance exceeds the next platform spawn distance, add a new platform
        if (minDistance > this.nextPlatformDistance) {
            let nextPlatformWidth = Phaser.Math.Between(this.scene.gameOptions.platformSizeRange[0], this.scene.gameOptions.platformSizeRange[1]);
            this.addPlatform(nextPlatformWidth, this.scene.sys.game.config.width + nextPlatformWidth / 2);
        }
    }

    // Update the speed of all currently active platforms
    updatePlatformSpeeds(newSpeed) {
        console.log('Updating platform speed to:', newSpeed);
        
        // Update velocity for all active platforms in the group
        this.platformGroup.getChildren().forEach((platform) => {
            platform.body.setVelocityX(-newSpeed);
        });
    }
}
