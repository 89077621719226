import Phaser from 'phaser';

class GoldenApple extends Phaser.Physics.Arcade.Sprite {
    constructor(scene, x, y, platformSpeed) {
        super(scene, x, y, 'goldenapple'); // Use the pizza image asset

        // Add the sprite to the scene
        scene.add.existing(this);
        scene.physics.add.existing(this); // Make sure physics is added

        // Set up physics body properties
        this.body.allowGravity = false; // Disable gravity
        this.setImmovable(true); // Make pizza immovable

        // Move the pizza leftward at the platform speed
        this.body.setVelocityX(-platformSpeed);

        // Floating animation
        this.tween = scene.tweens.add({
            targets: this,
            y: this.y - 10, 
            duration: 500,
            yoyo: true, 
            repeat: -1, 
            ease: 'Sine.easeInOut'
        });
    }

    update() {
        // Check if item goes off-screen
        if (this.x < -this.width) {
            this.destroy(); // Destroy the sprite completely
        }
    }
}


export default GoldenApple;