import { useEffect, useRef, useState} from "react";
import { Input, Container, Row, Col, Card, Label, CardText, 
    Modal,  ModalBody, Button} from 'reactstrap';
import './style.css';
import Dropzone from 'react-dropzone'
import axios from 'axios';
import API from "../../utils/API";
import {useParams} from 'react-router-dom';
import Jeopardy from "../../games/jeopardy/jeopardy";
import cat from '../../games//jeopardy/assets/animal-assets/cat.png';
import dog from '../../games//jeopardy/assets/animal-assets/dog.png';
import elephant from '../../games//jeopardy/assets/animal-assets/elephant.png';
import giraffe from '../../games//jeopardy/assets/animal-assets/giraffe.png';
import lion from '../../games//jeopardy/assets/animal-assets/lion.png';
import tiger from '../../games//jeopardy/assets/animal-assets/tiger.png';
import jeopardyImg from '../../assets/images/jeopardy.png';
// import { StoreContext } from "./context/store/storeContext";
const shuffleArray = (array) => {
    let newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
};
  
const JeopardySetUp = () => {

    const {id} = useParams();
    const [questionset, setQuestionSet] = useState({});
    const [teamCount, setTeamCount] = useState(2);
    const [buzzerChecked, setBuzzerChecked] = useState(false);
    const [gameStarted, setGameStarted] = useState(false);
    const [teams,setTeams] = useState();
    const [teamOptions] = useState({
        0: { name: "cat", image: cat, points:0 },
        1: { name: "dog", image: dog, points:0 },
        2: { name: "elephant", image: elephant, points:0 },
        3: { name: "giraffe", image: giraffe, points:0 },
        4: { name: "lion", image: lion, points:0 },
        5: { name: "tiger", image: tiger, points:0 }
      });
  
    useEffect(()=>{
        API.getJeopardy(id)
            .then(res => { 
                setQuestionSet(res.data);

                // setGameType(res.data.gametype);
            })
            .catch(err => console.log(err));
    },[]);

   const onTeamCountChange = e =>{
    let value = parseInt(e.target.value, 10);
    // Enforce the min/max values
    if (value < 2) value = 2;
    if (value > 6) value = 6;
    
    setTeamCount(value);
   }
   const onBuzzerCheckClick = e =>{
    setBuzzerChecked(!buzzerChecked);
   }
   const onGameStart =e=>{
    let teamTemp={};
    //~~~~~~~~~~~~~~~~~handle teams ~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    const assignTeams = (numTeams) => {
      const shuffledOptions = shuffleArray(Object.values(teamOptions));
      return shuffledOptions.slice(0, numTeams);
    };
    
    const assignedTeams = assignTeams(teamCount);

    for (let i =0; i< Number(teamCount);i++ ){
      teamTemp[i] = assignedTeams[i]
    }
    setTeams(teamTemp);

    setGameStarted(true);
   }
  return (

    <div>
        {gameStarted?
            <Jeopardy
                questionset={questionset}
                teamCount={teamCount}
                buzzerChecked={buzzerChecked}
                teams={teams}
            ></Jeopardy>



          :
            <div className="background-blue-95vh vertical-center ">
                <Container className="h-100">
                    <Row className="h-100">
                        <Col xs="3">
                        </Col>
                        <Col md="12"lg="6">
                            <Card className="text-center">
                                <h3 className="mb-3">{questionset.title}</h3>
                                {questionset.image ? <img className='setup-image mb-3' src={questionset.image}></img>: <img className='setup-image mb-3' src={jeopardyImg}></img>}
                                {questionset.description && <p className="mb-3" >{questionset.description}</p>}
                                {/* team select counter */}
                                <Container className="">
                                    <Row className="justify-content-center align-items-center ">
                                        <Col xs="auto" className="mb-3">
                                            <Label htmlFor="teamCount">Enter # of Teams (2-6)</Label>
                                        </Col>
                                        <Col xs="auto" className="mb-3">
                                        <Input
                                                    type="number"
                                                    id="teamCount"
                                                    value={teamCount} // Bind the input to teamCount state
                                                    onChange={onTeamCountChange}
                                                    min="2" max="6" // Set min and max in the input itself as well
                                                />
                                        </Col>
                                    </Row>
                                </Container>

                                <p for='#teamcount' className="mb-3">Use Buzzer <i class={`fas fa-check check-button ${buzzerChecked ? 'is-checked' : ''}`} onClick={e=>onBuzzerCheckClick(e)}></i></p>
                                <button onClick={e=>onGameStart()} className="btn btn-rounded nav-btn-light-blue navbar-btn">Start</button>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>

        }
    </div>

  );
};

export default JeopardySetUp; 