import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export default {
  // ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ USER AUTHENTICATION API~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  authenticate: () => {
    return axios.get(`${apiUrl}/authenticate`, { withCredentials: true });
  },
  login: (data) => {
    return axios.post(`${apiUrl}/login`, data, { withCredentials: true });
  },
  // creates a new user
  signUp: (newuser) => {
    return axios.post(`${apiUrl}/signup`, newuser, { withCredentials: true });
  },
  signOut: () => {
    return axios.get(`${apiUrl}/signout`, { withCredentials: true });
  },
  googleAuth: (token) => {
    return axios.post(`${apiUrl}/auth/google/token`, token, { withCredentials: true });
  },
  sendEmailVerification: (email) => {
    return axios.post(`${apiUrl}/api/send-verification-email`, email, { withCredentials: true });
  },
  verifyEmailCode: (data) => {
    return axios.post(`${apiUrl}/api/verify-email`, data, { withCredentials: true });
  },
  sendResetEmail: (data) => {
    return axios.post(`${apiUrl}/api/request-password-reset`, data, { withCredentials: true });
  },
  resetPassword: (data) => {
    return axios.post(`${apiUrl}/api/reset-password`, data, { withCredentials: true });
  },
  updatePassword: (data) => {
    return axios.post(`${apiUrl}/api/update-password`, data, { withCredentials: true });
  },
  updateUserName: (data) => {
    return axios.post(`${apiUrl}/api/update-username`, data, { withCredentials: true });
  },

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~QUESTION SET API ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  // creates a new question set
  createSet: (questionSet) => {
    return axios.post(`${apiUrl}/createSet`, questionSet, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      withCredentials: true
    });
  },
  updateSet:(questionSetData)=>{
    return axios.put(`${apiUrl}/updateSet`, questionSetData, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      withCredentials: true
    });
  },
  // gets all question sets
  getAllSets: (data) => {
    return axios.get(`${apiUrl}/getAllSets`, {
      params: data,
      withCredentials: true
    });
  },
  // gets data for a single set
  getSet: (id) => {
    return axios.get(`${apiUrl}/getSet/${id}`, { withCredentials: true });
  },
  // get all of the USER's question set
  getUserSets: (data) => {
    return axios.get(`${apiUrl}/getUserSets`, {
      params: data,
      withCredentials: true
    });
  },
  deleteSet: (id) => {
    return axios.delete(`${apiUrl}/deleteSet/${id}`, {
      withCredentials: true
    });
  },
  addPlayCount: (setId) => {
    return axios.put(`${apiUrl}/addPlayCount`, { setId });
  },

  //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~ CLASSES API~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
  getClasses: () => {
    return axios.get(`${apiUrl}/getclasses`, { withCredentials: true });
  },
  getStudents: (id) => {
    return axios.get(`${apiUrl}/getStudents/${id}`, { withCredentials: true });
  },
  addClass: (data) => {
    return axios.post(`${apiUrl}/addclass`, data, { withCredentials: true });
  },
  saveStudents: (data) => {
    return axios.post(`${apiUrl}/saveStudents`, data, { withCredentials: true });
  },
  deleteStudent: (data) => {
    return axios.post(`${apiUrl}/deleteStudent`, data, { withCredentials: true });
  },

  //~~~~~~~~~~~~~~~~~~~~~~~~JEOPARDY GAME API~~~~~~~~~~~~~~~~~~~~~~~~~~~
  getJeopardy: (id) => {
    return axios.get(`${apiUrl}/getJeopardy/${id}`, { withCredentials: true });
  },
  createJeopardyRoom: (data) => {
    return axios.post(`${apiUrl}/create-jeopardy-room`, data, { withCredentials: true });
  },
  saveJeopardySettings: (id, data) => {
    return axios.put(`${apiUrl}/jeopardysettings/${id}`, data, { withCredentials: true });
  },
  savePoints: (id, data) => {
    return axios.put(`${apiUrl}/jeopardypoints/${id}`, data, { withCredentials: true });
  },
  saveQuestion: (data) => {
    return axios.put(`${apiUrl}/jeopardyquestions`, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      withCredentials: true
    });
  },

  //~~~~~~~~~~~~~~~~~~~~~~~~Kooblo Questions API~~~~~~~~~~~~~~~~~~~~~~~~~~~
  getQuestions: (id) => {
    return axios.get(`${apiUrl}/kooblo/question/${id}`, { withCredentials: true });
  },
  getQuestionSet: (id) => {
    return axios.get(`${apiUrl}/kooblo/questionset/${id}`, { withCredentials: true });
  },
  editQuestion: (data) => {
    return axios.post(`${apiUrl}/kooblo/question/edit`, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      withCredentials: true
    });
  },
  deleteQuestion: (id) => {
    return axios.delete(`${apiUrl}/kooblo/question/delete/${id}`, { withCredentials: true });
  },

  //~~~~~~~~~~~~~~~~~~~~~~~~Kooblo Party Questions API~~~~~~~~~~~~~~~~~~~~~~~~~~~
  editKpQuestion: (data) => {
    return axios.post(`${apiUrl}/koobloparty/question/edit`, data, {
      headers: {
        "Content-Type": "multipart/form-data"
      },
      withCredentials: true
    });
  }
};
