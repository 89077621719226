import { useEffect, useRef, useState} from "react";
import './style.css';
import API from "../../utils/API";
import {useParams} from 'react-router-dom';
import WaitingRoom from "../../components/WaitingRoom";
import io from 'socket.io-client';
import MildlyInfuriatedBirdSettings from "../../components/KPsettings/MildlyInfuriatedBirdSettings";
import HungryHeroSettings from "../../components/KPsettings/HungryHeroSettings";
import HungryHero2Settings from "../../components/KPsettings/HungryHero2Settings";
import BasketballSettings from "../../components/KPsettings/BasketballSettings";
import HostScoreBoard from "../../components/HostScoreBoard";
import FinalScore from "../../components/FinalScore";
import FinalScoreWaiting from "../../components/FinalScoreWaiting";
import axios from 'axios';

// import { StoreContext } from "./context/store/storeContext";
  
const GameHost = () => {

    const {id} = useParams();
    const {gameType} = useParams();

    const [questionset, setQuestionSet] = useState({});
    const [timer, setTimer] = useState(15);
    const [timerInputValue, setTimerInputValue] = useState(timer); // Separate state for raw input
    const [createRoom, setCreateRoom] = useState(false);
    const [gameStarted, setGameStarted] = useState(false);
    const [socket, setSocket] = useState(null);
    const [roomId,setRoomId] = useState(null);
    const [qrCodeUrl, setQrCodeUrl] = useState(null);
    const [users, setUsers] = useState([]);
    const [userAvatar,setUserAvatar] = useState({});
    const [countdown, setCountdown] = useState(timer);
    const [gameEnded, setGameEnded] = useState(false);
    const [isWaitFinalScore, setIsWaitFinalScore] = useState(false);
    const [errors,setErrors] = useState(null);
    const roomIdRef = useRef(null); // <-- Create a roomIdRef using useRef
    const avatarRef = useRef(null); // <-- Create a roomIdRef using useRef
    const countdownRef = useRef(null); // Store the countdown request ID
    const [previousUsernames, setPreviousUsernames] = useState(new Set());
    const [sessionId, setSessionId] = useState(() => sessionStorage.getItem('sessionId'));
    const sessionIdRef = useRef(sessionId); // Use ref to persist the sessionId across renders
    useEffect(() => {
        sessionIdRef.current = sessionId;
    }, [sessionId]);
    // Whenever roomId is updated, update the roomIdRef as well
    useEffect(() => {
        roomIdRef.current = roomId;
        avatarRef.current = userAvatar
    }, [roomId,userAvatar]);

    useEffect(()=>{
        API.getQuestionSet(id).then(res=>{
            setQuestionSet(res.data);
        }).catch(err=>{
            console.log(err)
        })
    },[]);

    function handleRoomCreated(roomId) {
        setRoomId(roomId);
        setQrCodeUrl('/join/' + roomId); 

          // Base URL of the GoQR.me API
          const baseUrl = 'https://api.qrserver.com/v1/create-qr-code/';
          // Parameters for the API (size and data to encode)
          const params = `?size=300x300&data=${encodeURIComponent(roomId)}`;
          // Construct the full URL
          
          let qrcode = `${baseUrl}${params}`;

        // Once room ID is set, turn off the listener
        socket.off('KpRoomCreated', handleRoomCreated);
      }

    useEffect(()=>{
        if(!socket){
            return;
        }
        socket.emit('createKpRoom');
        socket.on('KpRoomCreated', handleRoomCreated); // Attach handler function
        
        return () => {
            socket.off('KpRoomCreated');
            socket.disconnect();
        };

    },[socket]);

    useEffect(() => {
        if(!socket){
            return;
        }
        // Listen for the 'updateUserList' event from the server
        socket.on('updateUserList', (updatedUsers) => {
          setUsers(updatedUsers);
          setErrors(null);
        });
        socket.on('updateScores', (updatedUsers) => {
            setUsers(updatedUsers);
        });
        socket.on('roomClosed', () => {
            setGameEnded(true);
        });
        socket.on('gameStarted', () => {
            setGameStarted(true);
            startCountdown();
        });
        socket.on('endGame', (users) => {
            setUsers(users)
            setGameEnded(true);
        });
        socket.on('timeEnd', () => {
            onEndGame()
        });

        // Clean up on component unmount
        return () => {
            socket.off('timeEnd');
          socket.off('updateUserList');
          socket.off('updateScores');
          socket.off('roomClosed');
          socket.off('gameStarted');
          sessionStorage.removeItem('sessionId');
        };
      }, [socket]);

      useEffect(() => {
        const currentUsernames = new Set(users.map(user => user.username));
        const newUsernames = [...currentUsernames].filter(username => !previousUsernames.has(username));
  
        if (newUsernames.length > 0) {
          const fetchAvatars = async () => {
            const updatedAvatars = await Promise.all(
              newUsernames.map(async username => {
                try {
                  const response = await axios.get(`https://api.multiavatar.com/${username}.svg`);
                  return { [username]: response.config.url };
                } catch (error) {
                  console.error("Error fetching avatar:", error);
                  return { [username]: '' }; // Fallback in case of error
                }
              })
            );
    
            // Combine the results into a single object
            const avatars = updatedAvatars.reduce((acc, avatar) => {
              return { ...acc, ...avatar };
            }, {});
    
            // Update state with the fetched avatars
            setUserAvatar(prevState => ({
              ...prevState,
              ...avatars
            }));
          };
    
          fetchAvatars();
    
          // Update the list of previously fetched usernames
          setPreviousUsernames(currentUsernames);
        }
      }, [users, previousUsernames]);
  

    const onTimerInputChange = (e) => {
    setTimerInputValue(e.target.value); // Update the raw input as the user types
    };

    const onTimerChange = () => {
    const value = parseInt(timerInputValue, 10); // Convert string to number
    if (value > 30) {
        setTimer(30); // Set to 30 if above max
        setTimerInputValue(30); // Update raw input to reflect max limit
    } else if (value < 3 || isNaN(value)) {
        setTimer(3); // Set to 3 if below min or invalid
        setTimerInputValue(3); // Update raw input to reflect min limit
    } else {
        setTimer(value); // Set to the valid input
    }
    };
    
    
   const onCreateRoom =e=>{
    //~~~~~~~~~~~~~~~~~handle teams ~~~~~~~~~~~~~~~~~~~~~~~~~~~~  
    const socketOptions = {
        withCredentials: true,
        query: { sessionId: sessionIdRef.current }, // Send the current sessionId
        reconnection: true,
        reconnectionAttempts: Infinity,
        reconnectionDelay: 1000,
        reconnectionDelayMax: 5000,
        timeout: 20000,
    };

    const newSocket = io(`${process.env.REACT_APP_SOCKET_URL}/koobloParty`, socketOptions);
    newSocket.on('sessionIdAssigned', (newSessionId) => {
        sessionStorage.setItem('sessionId', newSessionId);  // Store the sessionId in sessionStorage
        setSessionId(newSessionId);  // Update state with the new sessionId
    });
    // Before each reconnection attempt, update query with latest sessionId
    newSocket.io.on('reconnect_attempt', () => {
        newSocket.io.opts.query = { sessionId: sessionIdRef.current };
    });
    setSocket(newSocket);
    setCreateRoom(true);
   }

   const onGameStart = e => {
    if (users.length >= 1) {
        setErrors(null);
        const startTime = Math.floor(Date.now() / 1000);
        const endTime = startTime + timer * 60;
        socket.emit('startGame', roomId, gameType, questionset, endTime);
    } else {
        setErrors("Must have at least 1 user");
    }
};

const settings=()=>{
    switch (gameType) {
        case 'MildlyInfuriatedBird':
            return     <MildlyInfuriatedBirdSettings 
            questionset={questionset}
            timer={timerInputValue} // Pass the raw input value to the child component
            onTimerInputChange={onTimerInputChange} // Handle the typing in the child
            onTimerChange={onTimerChange} // Handle validation when the user is done
            onCreateRoom={onCreateRoom}
          />
      
        case 'basketball':
            return <BasketballSettings
            questionset={questionset}
            timer={timerInputValue} // Pass the raw input value to the child component
            onTimerInputChange={onTimerInputChange} // Handle the typing in the child
            onTimerChange={onTimerChange} // Handle validation when the user is done
            onCreateRoom={onCreateRoom}
        ></BasketballSettings>
        case 'hungryhero':
            return <HungryHeroSettings
            questionset={questionset}
            timer={timerInputValue} // Pass the raw input value to the child component
            onTimerInputChange={onTimerInputChange} // Handle the typing in the child
            onTimerChange={onTimerChange} // Handle validation when the user is done
            onCreateRoom={onCreateRoom}
        ></HungryHeroSettings>
        case 'hungryheroremix':
            return <HungryHero2Settings
            questionset={questionset}
            timer={timerInputValue} // Pass the raw input value to the child component
            onTimerInputChange={onTimerInputChange} // Handle the typing in the child
            onTimerChange={onTimerChange} // Handle validation when the user is done
            onCreateRoom={onCreateRoom}
        ></HungryHero2Settings>

        // Add more cases here for additional games
        default:
            console.error('Unknown game type:', gameType);
           return <h1> no game unavailable</h1>
      }
   }
   const startCountdown = () => {
    const startTime = Math.floor(Date.now() / 1000); // Convert start time to seconds
    const endTime = startTime + timer * 60; // End time in seconds

    const countdownInterval = () => {
        const currentTime = Math.floor(Date.now() / 1000); // Convert current time to seconds
        const remainingTime = Math.max(endTime - currentTime, 0); // Calculate remaining time in seconds
        
        setCountdown(remainingTime);

        if (remainingTime > 0) {
            countdownRef.current = requestAnimationFrame(countdownInterval); // Save the request ID
        }
    };

    countdownInterval(); // Start the countdown
};

const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
};
const onEndGame = () => {
    socket.emit('endGame', roomIdRef.current, avatarRef.current);
    setIsWaitFinalScore(true);
    if (countdownRef.current) {
        cancelAnimationFrame(countdownRef.current); // Stop the countdown
    }

};
return (
        <div>
            { gameEnded? ( // Check if the final score waiting state is true
                <div className="background-blue-95vh vertical-center waiting-room">
                    <FinalScore
                        users={users}
                        userAvatar={userAvatar}
                        roomId={roomId}
                    />
                </div>
            ) : isWaitFinalScore ? ( // Then check if the game has ended
                <div className="background-blue-95vh vertical-center waiting-room">
                    <FinalScoreWaiting /> {/* New component shown while waiting for final score */}
                </div>
            ) : gameStarted ? ( // If the game has started
                <HostScoreBoard
                    users={users}
                    userAvatar={userAvatar}
                    timer={formatTime(countdown)}
                    onEndGame={onEndGame}
                />
            ) : createRoom ? ( // When the room is created
                <WaitingRoom
                    roomId={roomId}
                    qrCodeUrl={qrCodeUrl}
                    users={users}
                    onGameStart={onGameStart}
                    setUserAvatar={setUserAvatar}
                    userAvatar={userAvatar}
                    errors={errors}
                />
            ) : ( // When no room or game started, render settings
                <div className="background-blue-95vh vertical-center justify-content-center">
                    {settings()}
                </div>
            )}
        </div>
    );
};
const dummyUsers = [
    {
        username: 'player1',
        score: 150,
    },
    {
        username: 'player2',
        score: 120,
    },
    {
        username: 'player3',
        score: 100,
    },
    {
        username: 'player4',
        score: 80,
    },
    {
        username: 'player5',
        score: 60,
    },
    {
        username: 'player6',
        score: 50,
    },
    {
        username: 'player7',
        score: 30,
    }
];

const dummyUserAvatars = {
    player1: 'https://api.multiavatar.com/player1.png',
    player2: 'https://api.multiavatar.com/player2.png',
    player3: 'https://api.multiavatar.com/player3.png',
    player4: 'https://api.multiavatar.com/player4.png',
    player5: 'https://api.multiavatar.com/player5.png',
    player6: 'https://api.multiavatar.com/player6.png',
    player7: 'https://api.multiavatar.com/player7.png',
};

export default GameHost; 