import React from 'react';
import './style.css';
import io from 'socket.io-client';
import { useEffect, useRef, useState} from "react";
import { Input, Container, Row, Col, Card, Label, CardText, 
    Modal,  ModalBody, Button} from 'reactstrap';
import MildlyInfuriatedBird from '../../games/mildlyInfuriatedBird/mildlyInfuriatedBird';
import TriviaBall from '../../games/basketball/basketballGame';
import HungryHero from '../../games/HungryHero/HungryHero';

import { useParams } from 'react-router-dom';
import FinalScore from '../../components/FinalScore';
const JoinGame = () => {
    const {id} = useParams();

    const [roomIdInput, setRoomIdInput] = useState('');
    const [joinedRoomId, setJoinedRoomId] = useState(null);
    const [socket, setSocket] = useState(null);
    const [userName,setUserName] = useState('');
    const [userReady, setUserReady] = useState(false);
    const [gameStarted, setGameStarted] = useState(false);
    const [gameType, setGameType] = useState('mildyInfuriatingBird');
    const [isGameOver, setIsGameOver] = useState(false); // State to trigger game end in child
    const [questionSet, setQuestionSet] = useState(null);
    const [error,setError] = useState(null)
    const [showFinalScores,setShowFinalScores] = useState(false);
    const [users,setUsers] = useState({});
    const [userAvatar,setUserAvatar] = useState({});
    const [pendingGameStart, setPendingGameStart] = useState(null);
    const [sessionId, setSessionId] = useState(() => sessionStorage.getItem('sessionId'));
    const sessionIdRef = useRef(sessionId); // Use ref to persist the sessionId across renders
    useEffect(() => {
        sessionIdRef.current = sessionId;
    }, [sessionId]);

    useEffect(()=>{
        if(id){

            const socketOptions = {
                withCredentials: true,
                query: { sessionId: sessionIdRef.current }, // Send the current sessionId
                reconnection: true,
                reconnectionAttempts: Infinity,
                reconnectionDelay: 1000,
                reconnectionDelayMax: 5000,
                timeout: 20000,
            };

            const newSocket = io(`${process.env.REACT_APP_SOCKET_URL}/koobloParty`, socketOptions);
            newSocket.on('sessionIdAssigned', (newSessionId) => {
                sessionStorage.setItem('sessionId', newSessionId);  // Store the sessionId in sessionStorage
                setSessionId(newSessionId);  // Update state with the new sessionId
            });
            // Before each reconnection attempt, update query with latest sessionId
            newSocket.io.on('reconnect_attempt', () => {
                newSocket.io.opts.query = { sessionId: sessionIdRef.current };
            });

    
            setSocket(newSocket);
    
            newSocket.emit('joinKpRoom', id);
            // setJoinedRoomId(id); // Store joined room ID for UI feedback
            return () => {
                newSocket.disconnect();
            };
    
        }
    },[])
    useEffect(() => {
        if(socket){
            socket.on('gameStarted', (gameType, questionSetData) => {
                // Store the game start data and wait for userReady to become true
                setPendingGameStart({ gameType, questionSetData });
            });
                
            socket.on('updateFinalScores', () => {
                setIsGameOver(true); // Ensure game is marked as over
            });

            socket.on('roomClosed', (users, userAvatar) => {
                setIsGameOver(true);  // Mark game as over when the room is closed
                setUsers(users);
                setUserAvatar(userAvatar);
                setShowFinalScores(true);
            });


            socket.on('error', error=>{
                setError(error)
            })
            socket.on('KpRoomJoined', roomId=>{
                setJoinedRoomId(roomId); // Store joined room ID for UI feedback
            })
            socket.on('userJoined', username=>{
                setUserReady(true);
            })
    
            return () => {
                socket.disconnect();
                socket.off('gameStarted');
                socket.off('roomClosed');
                socket.off('error');
                socket.off('KpRoomJoined');
                socket.off('userJoined');
                socket.off('updateFinalScores');
                sessionStorage.removeItem('sessionId');
            };    
        }  
        // Clean up the socket connection when the component unmounts
    }, [socket]);
    useEffect(() => {
        if (userReady && pendingGameStart) {
            setGameType(pendingGameStart.gameType);
            setGameStarted(true);
            setQuestionSet(pendingGameStart.questionSetData);
            setPendingGameStart(null);  // Clear pending game start after it's handled
        }
    }, [userReady, pendingGameStart]);
    
    // Assuming socket is already connected
    const emitScoreUpdate = (score) => {
        socket.emit('scoreUpdate', joinedRoomId, userName, score);
    };
  
    // Example of where you might call this function
    const handleScoreChange = (newScore) => {
        emitScoreUpdate(newScore);
    };
  
    const onRoomIdChange = e=>{
        setRoomIdInput(e.target.value);
    }

    const onUserNameChange = e=>{
        setUserName(e.target.value);
    }

    const handleJoinRoom = () => {
        // socket.on('teamOptions', handleTeamOptions);
        setError(null)
        const socketOptions = {
            withCredentials: true,
            query: { sessionId }, // Send sessionId if it exists
            reconnection: true,
            reconnectionAttempts: Infinity,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            timeout: 20000,
        };
    const newSocket = io(`${process.env.REACT_APP_SOCKET_URL}/koobloParty`, socketOptions);
        newSocket.on('sessionIdAssigned', (newSessionId) => {
            sessionStorage.setItem('sessionId', newSessionId);  // Store the sessionId in sessionStorage
            setSessionId(newSessionId);  // Update state with the new sessionId
        });
        setSocket(newSocket);

        newSocket.emit('joinKpRoom', roomIdInput);
        
    };

    const handleUserName = () => {
        setError(null)
        if(userName.length>16 || userName.length<3){
            setError("Username must be between 3 and 16 characters")
        } else {
            socket.emit('setUser', userName, joinedRoomId);
        }
    };
    const playGame= ()=>{
        switch (gameType) {
            case 'MildlyInfuriatedBird':
              return<MildlyInfuriatedBird
                    questionSet={questionSet}
                    handleScoreChange={handleScoreChange}
                    isGameOver={isGameOver}
                    ></MildlyInfuriatedBird>
            case 'basketball':
            return<TriviaBall
                    questionSet={questionSet}
                    handleScoreChange={handleScoreChange}
                    isGameOver={isGameOver}
            ></TriviaBall>
            case 'hungryhero':
                return<HungryHero
                        isRemix={false}
                        questionSet={questionSet}
                        handleScoreChange={handleScoreChange}
                        isGameOver={isGameOver}
                ></HungryHero>
                case 'hungryheroremix':
                    return<HungryHero
                            isRemix={true}
                            questionSet={questionSet}
                            handleScoreChange={handleScoreChange}
                            isGameOver={isGameOver}
                    ></HungryHero>
            
        }
    };
    return (
        <>
            {showFinalScores ? (
                    <div className="vertical-center waiting-room" style={{minHeight:"100vh"}}>
                        <FinalScore
                        users={users}
                        userAvatar={userAvatar}
                        />
                    </div>

            ) : gameStarted && gameType ? (
                playGame() // Show the game once it starts
            ) : userReady ? (
                <div className="buzzer-background vertical-center waiting-room">
                    <Container>
                        <Card className="text-center justify-content-center titan-one-small buzzer-team-select">
                            <h3>Waiting for the game to start...</h3>
                            <p>Please wait while other players join.</p>
                        </Card>
                    </Container>
                </div>
            ) : (
                <div className={"buzzer-background vertical-center " + (gameStarted ? '' : 'waiting-room')}>
                    {joinedRoomId ? (
                        <div>
                            <Container>
                                <Card className="text-center justify-content-center titan-one-small buzzer-team-select">
                                    <h3>Enter a Username</h3>
                                    {error && <p className='nunito' style={{ color: 'red' }}>{error}</p>}
                                    <Input
                                        type="text"
                                        id="username"
                                        className="text-center game-id-input"
                                        onChange={e => onUserNameChange(e)}
                                    />
                                    <div>
                                        <button
                                            onClick={e => handleUserName()}
                                            className="btn btn-rounded nav-btn-light-blue navbar-btn"
                                        >
                                            Enter
                                        </button>
                                    </div>
                                </Card>
                            </Container>
                        </div>
                    ) : (
                        <Container className="">
                            {/* <Row className="h-100">
                                <Col xs="3" />
                                <Col sm="12" md="6"> */}
                                    <Card className="text-center justify-content-center titan-one-small">
                                        <h3>Enter Room ID</h3>
                                        {error && <p className='nunito' style={{ color: 'red' }}>{error}</p>}
                                        <Input
                                            type="text"
                                            id="gameId"
                                            className="text-center game-id-input"
                                            onChange={e => onRoomIdChange(e)}
                                        />
                                        <div>
                                            <button
                                                onClick={e => handleJoinRoom()}
                                                className="btn btn-rounded nav-btn-light-blue navbar-btn"
                                            >
                                                Enter
                                            </button>
                                        </div>
                                    </Card>
                                {/* </Col>
                            </Row> */}
                        </Container>
                    )}
                </div>
            )}
        </>
    );
};

export default JoinGame;
