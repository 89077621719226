import { useEffect, useRef, useState} from "react";
import { Input, Container, Row, Col, Card, Label, CardText, 
    Modal,  ModalBody, Button} from 'reactstrap';
import './style.css';
import {useParams, useNavigate} from 'react-router-dom';
import basketballPreview from '../../assets/images/basketball.PNG'
import MildlyInfuriatedBirdPreview from '../../assets/images/MildlyInfuriatedBird.png'
import HungryHeroPreview from '../../assets/images/HungryHero.png'
import HungryHero2Preview from '../../assets/images/HungryHero2.png'

const KpGameSelect = () => {
  const navigate = useNavigate();
    const {id} = useParams();    
    const gameData = {
      'MildlyInfuriatedBird': {
        name: 'Mildly Infuriated Bird',
        description: 'Guide your bird through challenging obstacles while answering questions to keep it flying. Collect coins and soar to victory!',
        players: '2-30'
      },
      'basketball': {
        name: 'Trivia Dunk',
        description: 'Answer questions to earn shots at the hoop. The more accurate your answers, the better your chances of scoring big and out-dunking your opponents!',
        players: '2-30'
      },
      'HungryHero': {
        name: 'Hungry Hero',
        description: 'Jump over gaps, eat ice creams for extra points, and answer questions to keep playing if you fall or run out of energy!',
        players: '2-30'
      },
      'HungryHero2': {
        name: 'Hungry Hero: Remix',
        description: 'Hungry Hero with a twist! Jump over gaps, eat a variety of food for extra points or power ups , and answer questions to keep playing if you fall or run out of energy!',
        players: '2-30'
      },

    }
      const [selectedGame, setSelectedGame] = useState(null);
    const handleCardClick = (game) => {
      setSelectedGame(game);
    };  

    const startGame=()=>{
      let path;
      if (selectedGame === 'MildlyInfuriatedBird') {
        path = `/koobloparty/host/MildlyInfuriatedBird/${id}`;
      } else if (selectedGame === 'basketball') {
        path = `/koobloparty/host/basketball/${id}`;
      }else if (selectedGame === 'HungryHero') {
        path = `/koobloparty/host/hungryhero/${id}`;
      }else if (selectedGame === 'HungryHero2') {
        path = `/koobloparty/host/hungryheroremix/${id}`;
      }
      navigate(path);

    }
  return (

            <div className="background-blue-95vh ">
              <Container className="p-4"fluid>
                <Row>
                      <Col xs="12">
                      <Card className="w-100 text-center game-banner">
                        {selectedGame ? (
                          <Container>
                              <Row>
                                <Col lg="4" className="h-100">
                                  <img
                                    src={
                                      selectedGame === 'MildlyInfuriatedBird' 
                                        ? MildlyInfuriatedBirdPreview
                                        : selectedGame === 'basketball'
                                        ? basketballPreview
                                        : selectedGame === 'HungryHero'
                                        ? HungryHeroPreview
                                        : selectedGame === 'HungryHero2'
                                        ? HungryHero2Preview
                                        : null
                                    }
                                    alt={
                                      selectedGame === 'MildlyInfuriatedBird'
                                        ? 'MildlyInfuriatedBird'
                                        : selectedGame === 'basketball'
                                        ? 'basketball'
                                        : selectedGame === 'HungryHero'
                                        ? 'HungryHero'
                                        : selectedGame === 'HungryHero2'
                                        ? 'HungryHero2'
                                        : ''
                                    }
                                    className="game-image"
                                  />
                                </Col>
                                <Col lg="4" className="d-flex justify-content-center">
                                  <div>
                                    <h3 className="titan-one-regular">{gameData[selectedGame].name}</h3>
                                    <h4 className="nunito">Players: {gameData[selectedGame].players}</h4>
                                    <p className="nunito">{gameData[selectedGame].description}</p>
                                  </div>
                                </Col>
                                <Col lg="4" className="d-flex align-items-center justify-content-center">
                                  <button className="btn btn-rounded nav-btn-light-blue navbar-btn" onClick={startGame}>
                                    Start Game
                                  </button>
                                </Col>
                              </Row>
                            </Container>
                        ) : (
                          <h2 className="titan-one-regular">Select a Game</h2>
                        )}
                      </Card>
                      </Col>
                </Row>
              </Container>
              <Container className="h-100">
                  <Row className="h-100 d-flex justify-content-center align-items-center">
                    <Col md="auto" className="mb-4 d-flex justify-content-center align-items-cente">
                        <img                         
                        onClick={() => handleCardClick('MildlyInfuriatedBird')}
                        className={` game-logo ${selectedGame === 'MildlyInfuriatedBird' ? 'selected' : ''}`}
                        src={MildlyInfuriatedBirdPreview} alt="MildlyInfuriatedBird" />
                    </Col>

                    <Col md="auto" className="mb-4 text-center d-flex justify-content-center align-items-cente">
                        <img                         
                        onClick={() => handleCardClick('basketball')}
                        className={` game-logo ${selectedGame === 'basketball' ? 'selected' : ''}`}
                        src={basketballPreview} alt="basketball" />
                    </Col>
                    <Col md="auto" className="mb-4 d-flex justify-content-center align-items-cente">
                        <img                         
                        onClick={() => handleCardClick('HungryHero')}
                        className={` game-logo ${selectedGame === 'HungryHero' ? 'selected' : ''}`}
                        src={HungryHeroPreview} alt="HungryHero" />
                    </Col>
                    <Col md="auto" className="mb-4 d-flex justify-content-center align-items-cente">
                        <img                         
                        onClick={() => handleCardClick('HungryHero2')}
                        className={` game-logo ${selectedGame === 'HungryHero2' ? 'selected' : ''}`}
                        src={HungryHero2Preview} alt="HungryHero2" />
                    </Col>

                  </Row>
                </Container>
            </div>
  );
};

export default KpGameSelect; 