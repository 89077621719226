import React from 'react';
import './style.css'; // Ensure your CSS file is imported
import io from 'socket.io-client';
import { useEffect, useState,useRef} from "react";
import { Input, Container, Row, Col, Card } from 'reactstrap';
import { useParams } from 'react-router-dom';

const BuzzerButton = () => {
    const { id } = useParams();
    const [roomIdInput, setRoomIdInput] = useState('');
    const [joinedRoomId, setJoinedRoomId] = useState(null);
    const [teamOptions, setTeamOptions] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState(null);
    const [socket, setSocket] = useState(null);
    const [error, setError] = useState(null);
    const [roomJoined, setRoomJoined] = useState(false);
    const [sessionId, setSessionId] = useState(() => sessionStorage.getItem('sessionId'));
    const sessionIdRef = useRef(sessionId); // Use ref to persist the sessionId across renders
    useEffect(() => {
        sessionIdRef.current = sessionId;
    }, [sessionId]);

    useEffect(() => {
        const socketOptions = {
            withCredentials: true,
            query: { sessionId: sessionIdRef.current }, // Send the current sessionId
            reconnection: true,
            reconnectionAttempts: Infinity,
            reconnectionDelay: 1000,
            reconnectionDelayMax: 5000,
            timeout: 20000,
        };


        const newSocket = io(`${process.env.REACT_APP_SOCKET_URL}/jeopardy`, socketOptions);     
        newSocket.on('sessionIdAssigned', (newSessionId) => {
            sessionStorage.setItem('sessionId', newSessionId);  // Store the sessionId in sessionStorage
            setSessionId(newSessionId);  // Update state with the new sessionId
        });
        // Before each reconnection attempt, update query with latest sessionId
        newSocket.io.on('reconnect_attempt', () => {
            newSocket.io.opts.query = { sessionId: sessionIdRef.current };
        });
    
    
        setSocket(newSocket);
        newSocket.on('error', error => {
            console.log(error);
            setError(error);
        });
        return () => {
            newSocket.disconnect();
            sessionStorage.removeItem('sessionId');
        };
    }, []);

    useEffect(() => {
        if (id && socket) {
            socket.on('teamOptions', handleTeamOptions);
            socket.emit('joinRoom', id);
            setRoomIdInput(id);
            setJoinedRoomId(id); // Store joined
        }
    }, [socket]);

    const onRoomIdChange = e => {
        setRoomIdInput(e.target.value);
    };

    const handleTeamOptions = (options) => {
        setTeamOptions(options);
        setRoomJoined(true); // Set roomJoined to true when options are received
        // Turn off the listener after receiving data
        socket.off('teamOptions', handleTeamOptions);
    };

    const handleJoinRoom = () => {
        socket.on('teamOptions', handleTeamOptions);
        socket.emit('joinRoom', roomIdInput);
        setJoinedRoomId(roomIdInput); // Store joined room ID for UI feedback
    };

    const handleTeamSelect = (team) => {
        setSelectedTeam(team);
        socket.emit('selectTeam', team, roomIdInput); // Send selected team to server
    };

    const handleBuzz = () => {
        socket.emit('buzz', roomIdInput); // Notify server of buzzer press
    };

    return (
        <div className={"buzzer-background vertical-center " + (selectedTeam ? '' : 'waiting-room')}>
            {selectedTeam ? (
                <div className="buzzer-container">
                    <button className="buzzer-button btn titan-one-regular" onClick={() => handleBuzz()}>
                        <span className="buzzer-button-front">Kooblo!</span>
                    </button>
                </div>
            ) : roomJoined ? (
                <div>
                    <Container>
                        <Card className="text-center justify-content-center titan-one-small buzzer-team-select">
                            <Row className="text-center">
                                <Col>
                                    <div>
                                        <h3>Select Team</h3>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="text-center justify-content-center team-select-container">
                                {teamOptions && Object.keys(teamOptions).map(key => (
                                    <Col key={key} onClick={e => handleTeamSelect(key)} className="team-select-item">
                                        <img className="team-select-image" src={teamOptions[key].image} alt={teamOptions[key].name} />
                                        <p>{teamOptions[key].name}</p>
                                    </Col>
                                ))}
                            </Row>
                        </Card>
                    </Container>
                </div>
            ) : (
                <Container>
                    <Card className="text-center justify-content-center titan-one-small">
                        <h3>Enter Buzzer ID</h3>
                        {error && <p className="nunito" style={{ color: 'red' }}>{error}</p>}
                        <Input type="text" id="gameId" className="text-center game-id-input" onChange={e => onRoomIdChange(e)} />
                        <div>
                            <button onClick={e => handleJoinRoom()} className="btn btn-rounded nav-btn-light-blue navbar-btn">Enter</button>
                        </div>
                    </Card>
                </Container>
            )}
        </div>
    );
};

export default BuzzerButton;
