import React, { Component} from "react";
import "./style.css"
import API from "../../utils/API";
import { useState, useEffect, useRef} from "react";
import { Input, Container, Row, Col, Button, ModalBody, Modal, Label} from 'reactstrap';
import './style.css';
import Dropzone from 'react-dropzone'
import axios from 'axios';
import {useParams} from 'react-router-dom';

//state 
const EditJeopardy = (data) => {
    const apiKey = process.env.REACT_APP_UNSPLASH_API_KEY;

    const {id} = useParams();
    const [modalToggle, setModalToggle] = useState(false);
    const [pointEditorModal, setPointEditorModal] = useState(false);
    const [points, setPoints] = useState({
        1:"100",
        2:"200",
        3:"300",
        4:"400",
        5:"500",
        6:"600",
        7:"700", 
        8:"800"
    });
    const [pointChanges, setPointChanges] = useState({})
    const [questionSetData, setQuestionSetData] = useState(data);
    const [categoryNames, setCategoryNames] = useState({});
    const [currentCategoryName, setCurrentCategoryName] = useState("");

    const [totalColumns, setTotalColumns ] = useState(6);
    const [totalRows, setTotalRows ] = useState(6);
    const [questions,setQuestions] = useState({});
    const [currentQuestion, setCurrentQuestion] = useState("");
    const [currentAnswer, setCurrentAnswer] = useState(""); 
    const [currentModal, setCurrentModal] = useState("");

    //used to show q/a for all fields
    const [showAll, setShowAll] = useState(false);
    const [showSaveNotification, setSaveShowNotification] = useState(false);

    // variables for image input modal
    const [files, setFiles] = useState({}) 
    const [filesChange, setFilesChange] = useState([]) 
    const [URLModal, setURLModal] = useState(false);
    const [ImgApiModal, setImgApiModal] = useState(false);
    const[previewImage,setPreviewImage]= useState({});
    const[currentPreviewImage,setCurrentPreviewImage]= useState("");

    const[imageURL, setimageURL] = useState("");
    const[imageApiDesc, setimageApiDesc] = useState("");
    const[apiImages, setApiImages] = useState([]);
    const[apiPageCount, setApiPageCount] = useState(1);
    const [loading, setLoading] = useState(false);
    const modalBodyRef = useRef(null);

    // END varaibles for image input modal

    // category name editor
    const [editCategoryName, setEditCategoryName] = useState({1:false,
        2:false,
        3:false,
        4:false,
        5:false,
        6:false,
        7:false,
        8:false,
        9:false
    })
    const toggleShowAll = () => {
        console.log(questionSetData.data);
        setShowAll(!showAll);
    };
    const toggleEditCategoryName = (e, categoryNum) => {
        e.stopPropagation()
        setCurrentCategoryName(categoryNames[categoryNum]);
        let editCategoryNameCopy ={1:false,
            2:false,
            3:false,
            4:false,
            5:false,
            6:false,
            7:false,
            8:false,
            9:false
        };
        editCategoryNameCopy[categoryNum]=!editCategoryNameCopy[categoryNum]
        setEditCategoryName(editCategoryNameCopy);

    };    
    const toggleEditCategoryNameOff = e => {
        
        setEditCategoryName({1:false,
            2:false,
            3:false,
            4:false,
            5:false,
            6:false,
            7:false,
            8:false,
            9:false
        });

    };

    const togglePointEditorModal = () => {
        let pointsCopy = {...points}
        setPointChanges(pointsCopy);
        setPointEditorModal(!pointEditorModal)
    };
    const toggleURLModal = () => setURLModal(!URLModal);
    const toggleImgApiModal = () => setImgApiModal(!ImgApiModal);
    const togglemodalToggle = () => setModalToggle(!modalToggle);

    useEffect(()=>{
        let jeopardyData = data.data;
        if(jeopardyData.Jeopardy){
            setTotalColumns(jeopardyData.Jeopardy.columns);
            setTotalRows(jeopardyData.Jeopardy.rows);
            let categoryNamesTemp = {...categoryNames}
            for(let i = 0; i <= jeopardyData.Jeopardy.columns; i++){
                if(jeopardyData.Jeopardy[i]){
                    categoryNamesTemp[i]= jeopardyData.Jeopardy[i];
                    
                }
            }
            console.log(categoryNamesTemp)
            setCategoryNames(categoryNamesTemp);
        }
        if(jeopardyData.JeopardyPoint){
            setPoints(jeopardyData.JeopardyPoint);
        }
        if(jeopardyData.JeopardyQuestions){
            let questionsTemp = {...questions};
            let filesTemp={...files};
            for(let i =0; i< jeopardyData.JeopardyQuestions.length;i++){
                let position = jeopardyData.JeopardyQuestions[i].position;
                if(position){
                    questionsTemp[position]={};
                    questionsTemp[position].question=jeopardyData.JeopardyQuestions[i].question;
                    questionsTemp[position].answer=jeopardyData.JeopardyQuestions[i].answer;
                    questionsTemp[position].image=jeopardyData.JeopardyQuestions[i].image;
                    questionsTemp[position].id=jeopardyData.JeopardyQuestions[i].id;
                }
                filesTemp[position] = jeopardyData.JeopardyQuestions[i].image;
                setFiles(filesTemp);
            }
            setQuestions(questionsTemp)
        }
    },[]);

    const onPointChange = (e,row) => {
        let pointsCopy = {...pointChanges};
        pointsCopy[row]= e.target.value
        setPointChanges(pointsCopy);
    }
    const savePoints = e =>{
        setPoints({...pointChanges});
        API.savePoints(id, {...pointChanges})
        .then(res => 
            console.log(res)
        )
        .catch(err => console.log(err))

        setPointEditorModal(!pointEditorModal)
    }
    const onCategoryNameChange = (e,columnKey) => {
        let categoryName = {...categoryNames};
        categoryName[columnKey] = e.target.value;
        setCategoryNames(categoryName);
    }
    const saveSettingChanges = e => {
        let jeopardySettings = {...categoryNames};
        jeopardySettings.columns = totalColumns;
        jeopardySettings.rows=totalRows
        API.saveJeopardySettings(id, jeopardySettings)
        .then(res => {
            console.log(res)
            setSaveShowNotification(true);

            // Hide notification after 3 seconds (adjust as needed)
            setTimeout(() => {
                setSaveShowNotification(false);
            }, 3000);
        }).catch(err => console.log(err))
    }
    // question and answer state
    const onQuestionChange = e => {
        setCurrentQuestion(e.target.value);
    }
    const onAnswerChange = e => {
        setCurrentAnswer(e.target.value);
      }
    const saveQuestion = e => {
        let data = {
            question:currentQuestion,
            answer:currentAnswer,
            files:filesChange,
            questionSetId:id,
            position:currentModal
        };
        API.saveQuestion(data)
        .then(res =>{
        })
        .catch(err => console.log(err))
        let question = {...questions};
        question[currentModal].question=currentQuestion;
        question[currentModal].answer=currentAnswer;
        question[currentModal].image=currentPreviewImage;
        let previewImageTemp = {...files};
        previewImageTemp[currentModal] = currentPreviewImage;
        setFiles(previewImageTemp);
        setQuestions(question);
        setModalToggle(!modalToggle);
    }

    // FILEUPLOAD STATE
    const onFileChange = (e) => {
        if(e.target.files.length !== 0){
            setCurrentPreviewImage(URL.createObjectURL(e.target.files[0]))
            setFilesChange(e.target.files);
        }  
    
    }

    //  SEARCH BY API STATE
    const onImageApiDescChange = e =>{
        setimageApiDesc(e.target.value)
        setApiPageCount(1);

    }

    const searchImageApi = () => {
        setLoading(true);
        setApiImages([]); // Reset the images array
        setApiPageCount(1); // Reset to the first page
        axios.get(`https://api.unsplash.com/search/photos`, {
            params: {
                query: imageApiDesc,
                page: apiPageCount,
                per_page: 20,
            },
            headers: {
                Authorization: `Client-ID ${apiKey}`
            }
        }).then(res => {
                // Handle the response
                setApiImages(res.data.results);
                setApiPageCount(2);
            })
            .catch(error => {
                console.error('Error fetching images from Unsplash:', error);
            })
            .finally(() => {
                setLoading(false);
            });
      };
      
    const setImageAPI = imgURl =>{
        setCurrentPreviewImage(imgURl);
        setFilesChange(imgURl);
        setImgApiModal(!ImgApiModal);
    }
    useEffect(() => {
        const handleScroll = (event) => {
            const ApiImgBody = event.target;
            if (ApiImgBody.scrollTop + ApiImgBody.clientHeight >= ApiImgBody.scrollHeight - 5 && !loading) {
                console.log(apiPageCount)
                setLoading(true);
                fetchImages();
            }
        };

        const ApiImgBody = document.getElementById('ApiImgBody');
        if (ApiImgBody) {
            ApiImgBody.addEventListener('scroll', handleScroll);
            return () => {
                ApiImgBody.removeEventListener('scroll', handleScroll);
            };
        }
      }, [ImgApiModal, loading, ]);
      const fetchImages = () => {
        axios.get(`https://api.unsplash.com/search/photos`, {
            params: {
                query: imageApiDesc,
                page: apiPageCount,
                per_page: 20,
            },
            headers: {
                Authorization: `Client-ID ${apiKey}`
            }
        }).then(res => {
                // Handle the response
                setApiImages(prevImages => [...prevImages, ...res.data.results]);
                setApiPageCount(prevPageCount => prevPageCount + 1);
            })
            .catch(error => {
                console.error('Error fetching images from Unsplash:', error);
            })
            .finally(() => {
                setLoading(false);
            });

    };

// IMAGE URL UPLOAD STATE
    const onURLChange = e =>{
        setimageURL(e.target.value);
    }
    const handleURLSubmit = e => {
        setCurrentPreviewImage(imageURL);
        setFilesChange(imageURL);
        setURLModal(!URLModal);
    }
    //REMOVE PREVIEW IMAGE ALLOW UPLOAD OF A NEW IMAGE
    const removePreviewImage = e =>{
        setCurrentPreviewImage("");
        setFilesChange([]);
    }
// used to reference button to a file input. using button over input for design purposes
    const fileInput = useRef();

    const openModal = modalID =>{
        if(files[modalID]){
            setCurrentPreviewImage(files[modalID]);
            setFilesChange(files[modalID]);
        } else{
            setCurrentPreviewImage("");
            setFilesChange("");
        }
        if(!questions[modalID]){
            let question = questions
            question[modalID] ={
                question:"",
                answer:""
            };
            setCurrentQuestion("");
            setCurrentAnswer("");
            setQuestions(question)
            // console.log(question)b 
            // console.log(questions)
        } else{
            setCurrentQuestion(questions[modalID].question);
            setCurrentAnswer(questions[modalID].answer);
        }
        setCurrentModal(modalID);
        setModalToggle(!modalToggle);
    };

    const addRow = e =>{
        setTotalRows(totalRows+1);

    }
    const removeRow = e =>{
        setTotalRows(totalRows-1);
    }
    const addColumn = e =>{
        setTotalColumns(totalColumns+1);
    }
    const removeColumn = e =>{
        setTotalColumns(totalColumns-1);
    }
        return (
            <div className="game-background position-relative" onClick={()=>toggleEditCategoryNameOff()}>
                    <p className={` titan-one-small text-center save-notification ${!showSaveNotification ? 'hidden' : ''}`}>
                        Game Saved!
                    </p>

                <Container fluid className='p-top-16'>
                    <Row className="justify-content-center align-items-center">
                        <Col xs='auto' className="text-center">
                        <div>
                                {showAll ? <button onClick={()=>toggleShowAll()} className="btn grow-small btn-rounded nav-btn-light-blue navbar-btn">Show Points</button>:<button onClick={()=>toggleShowAll()} className="btn grow-small btn-rounded nav-btn-light-blue navbar-btn">Show Question and Answers</button>}
                                <button onClick={()=>togglePointEditorModal()} className="btn grow-small btn-rounded nav-btn-light-blue navbar-btn">Edit Points</button>
                                {totalRows < 7 ?  <button onClick={()=>addRow()} className="btn grow-small btn-rounded nav-btn-light-blue navbar-btn">Add Row</button>:<button className="btn btn-rounded nav-btn-light-red navbar-btn">At Max Rows</button>}
                                {totalRows >3 ?  <button onClick={()=>removeRow()} className="btn grow-small btn-rounded nav-btn-light-blue navbar-btn">Remove Row</button>:<button className="btn btn-rounded nav-btn-light-red navbar-btn">At Min Rows</button>}
                                {totalColumns < 6 ? <button onClick={()=>addColumn()} className="btn grow-small btn-rounded nav-btn-light-blue navbar-btn">Add Column</button> :<button className="btn btn-rounded nav-btn-light-red navbar-btn">At Max Columns</button>}
                                {totalColumns >3 ?<button onClick={()=>removeColumn()} className="btn grow-small btn-rounded nav-btn-light-blue navbar-btn">Remove Column</button>:<button className="btn btn-rounded nav-btn-light-red navbar-btn">At Min Columns</button>}
                                <button onClick={()=>saveSettingChanges()} className="btn grow-small btn-rounded btn-green navbar-btn" >Save Changes</button>
                        </div>
                        </Col>

                    </Row>
                </Container>
                {/* modal for editing points */}
                <Modal isOpen={pointEditorModal} toggle={togglePointEditorModal} centered={true} >
                    <ModalBody>
                        {[...Array(totalRows).keys()].map(rowKey =>{ 
                            let row=rowKey+1
                            return <Container key={rowKey}>
                                        <Row className="align-items-center">
                                            <Col lg="4" style={{textAlign:"right"}}>
                                                <Label className="titan-one-regular">Row {row} Points: </Label>
                                            </Col>
                                            <Col lg="6">
                                                <Input type="text" name="text" placeholder = "Row Points" id="RowPoints" defaultValue={points[row]} onChange={e=>(onPointChange(e, row))}/>    
                                            </Col>
                                        </Row>

                                    </Container>

                        })}        
                    </ModalBody>
                    <div className="modal-button">
                        <button onClick={savePoints} className="btn grow-small btn-rounded nav-btn-light-blue navbar-btn">Save</button>
                        <Button color="secondary" onClick={togglePointEditorModal}>
                            Cancel
                        </Button>
                    </div>
                </Modal>

                {/* MODAL */}
                <Modal size="xl" isOpen={modalToggle} toggle={togglemodalToggle} centered={true} >
                    <ModalBody>
                        <Row>
                                    <Col lg="6" className="text-center">
                                        
                                        {currentPreviewImage ?
                                        <div>
                                            <img src={currentPreviewImage} alt="preview-image" className="preview-image">
                                            </img>
                                            <button onClick={removePreviewImage} className="remove-preview-image">Remove</button>
                                        </div>
                                        
                                        :
                                        <Dropzone multiple={false}noKeyboard={true} noClick={true} onDrop={acceptedFiles => {setPreviewImage(URL.createObjectURL(acceptedFiles[0])); setFiles(acceptedFiles)}}>
                                            {({getRootProps, getInputProps}) => (
                                                <section >
                                                <div {...getRootProps()} className=" titan-one-regular dropzone">
                                                    <input accept="image/*" {...getInputProps()} />
                                                        <div > 
                                                            <p>Drag 'n' drop an image here</p>
                                                            <p>or</p>

                                                            {/* use image api to search */}
                                                            <button onClick={toggleImgApiModal} className="btn grow-small btn-rounded nav-btn-light-blue navbar-btn">Search an Image</button>

                                                            {/* <Modal size="xl" isOpen={ImgApiModal} toggle={toggleImgApiModal} centered={true} >
                                                                <ModalBody>
                                                                <Label className="titan-one-regular" for="urlText">Search for an image</Label>
                                                                <Input id="urlText" onChange={e=>(onImageApiDescChange(e))}/>
                                                                    {apiImages.map(image=>(
                                                                                <img onClick={e=>(setImageAPI(image.previewURL))} src={image.previewURL} alt={image.name} width={100} height={100}/>

                                                                    ))}
                                                                </ModalBody>
                                                                <div className="modal-button">
                                                                <button onClick={searchImageApi } className="btn grow-small btn-rounded nav-btn-light-blue navbar-btn">Search</button>
                                                                <Button color="secondary" onClick={toggleImgApiModal}>
                                                                    Cancel
                                                                </Button>
                                                                </div>
                                                            </Modal> */}

                                                            <Modal size="xl" isOpen={ImgApiModal} toggle={toggleImgApiModal} centered={true} >
                                                                <div className="m-3">
                                                                <Label className="titan-one-small" for="urlText">Search for an image</Label>
                                                                <Input id="urlText" onChange={e=>(onImageApiDescChange(e))} 
                                                                onKeyDown={e => {
                                                                    if (e.key === 'Enter') {
                                                                    searchImageApi();
                                                                    }
                                                                }}/>
                                                                </div>
                                                                <ModalBody id="ApiImgBody" style={{ maxHeight: '800px', overflowY: 'auto' }} ref={modalBodyRef}>
                                                                    <Container>
                                                                        <Row className="text-center justify-content-center align-items-center"> 
                                                                            {apiImages.map(image=>(
                                                                                <img className="m-2 pixaImage"onClick={e=>(setImageAPI(image.urls.small))} src={image.urls.thumb} alt={image.name} style={{cursor:'pointer',borderRadius:'5px',maxWidth:'300px', maxHeight:'300px'}}/>
                                                                            ))}
                                                                       
                                                                        </Row>
                                                                    </Container>

                                                                    {loading && <p>Loading more images...</p>}

                                                                </ModalBody>
                                                                <div className="modal-button">
                                                                <button onClick={searchImageApi } className="btn  btn-rounded nav-btn-light-blue navbar-btn">Search</button>
                                                                <Button color="btn btn-rounded btn-red navbar-btn" onClick={toggleImgApiModal}>
                                                                    Cancel
                                                                </Button>
                                                                </div>
                                                            </Modal>

                                                            {/* upload a local file */}

                                                            <button onClick={()=>fileInput.current.click()} className="btn grow-small btn-rounded nav-btn-light-blue navbar-btn">Upload an Image</button>
                                                            <input ref={fileInput} type="file" accept="image/*"  style={{ display: 'none' }} onChange={onFileChange}/>

                                                            {/* upload by url */}
                                                            <Modal isOpen={URLModal} toggle={toggleURLModal} centered={true} >
                                                                <ModalBody>
                                                                <Label className="titan-one-regular" for="urlText">Upload by URL</Label>
                                                                <Input id="urlText" onChange={e=>(onURLChange(e))}/>
                                                                </ModalBody>
                                                                <div className="modal-button">
                                                                <button onClick={handleURLSubmit} className="btn grow-small btn-rounded nav-btn-light-blue navbar-btn">Upload</button>
                                                                <Button color="secondary" onClick={toggleURLModal}>
                                                                    Cancel
                                                                </Button>
                                                                </div>
                                                            </Modal>

                                                            <button onClick={toggleURLModal } className="btn grow-small btn-rounded nav-btn-light-blue navbar-btn">Upload by URL</button>
                                                        </div>
                                                </div>
                                                </section>
                                            )}
                                        </Dropzone>
                                        }

                                    </Col>
                        <Col lg="6" className="text-center">

                            <Label className="titan-one-regular" for="questionText">Question</Label>
                            <Input type="textarea" rows="3" name="text" placeholder = "Question" id="questionText" defaultValue={questions[currentModal] ? questions[currentModal].question:""} onChange={e=>(onQuestionChange(e))}/>
                            <Label className="titan-one-regular" for="answerText">Answer</Label>
                            <Input type="textarea" rows="3" name="text" placeholder = "Answer" id="answerText" defaultValue={questions[currentModal] ? questions[currentModal].answer:""} onChange={e=>(onAnswerChange(e))}/>
                        </Col>

                        </Row>
                    </ModalBody>
                    <div className="modal-button">
                    <button onClick={saveQuestion} className="btn grow-small btn-rounded nav-btn-light-blue navbar-btn">Save</button>
                    <button className="btn grow-small btn-rounded nav-btn-light-blue navbar-btn" onClick={togglemodalToggle}>
                        Cancel
                    </button>
                    </div>
                </Modal>
                {/* GAME BOARD */}
                <div className='game-body'>

                    <div className={"grid-container grid-container-"+ totalColumns}>

                        {[...Array(totalColumns).keys()].map( columnKey => {
                            let columnID = ["a","b","c","d","e","f"];
                            columnKey = columnKey+1;
                            return <div key={columnKey}>


                                <div className={"grid-category jeopardy-col-"+columnKey} style={{height:60/totalRows+"vh"}} >
                                    {editCategoryName[columnKey]? <div>
                                        <Input type="textarea" rows="3" name="text" placeholder = "Category Name" id="categoryName" defaultValue={categoryNames[columnKey]} onKeyDown={e=>{if (e.key === 'Enter'){toggleEditCategoryNameOff()}}}onChange={e=>(onCategoryNameChange(e,columnKey))} onClick={e=>e.stopPropagation()}/>
                                    </div>
                                    :
                                    <p className='grid-category-name' onClick={(e)=>toggleEditCategoryName(e, columnKey)}>{categoryNames[columnKey] ? categoryNames[columnKey] : "click to add a category name"}</p>
                                    }
                                </div>



                                {[...Array(totalRows).keys()].map( rowKey => {
                                    rowKey=rowKey+1
                                    return <div key={rowKey} className={"grid-item jeopardy-col-"+columnKey+" a1-modal " +(showAll? 'preview-qa':'')} style={{height:60/totalRows+"vh"}} onClick={() => openModal(columnID[columnKey-1]+rowKey)} >
                                            {showAll ? 
                                            <div>
                                                <Container fluid>
                                                    <Row className="preview-qa-text" >
                                                        <Col xs="6">
                                                        {questions[columnID[columnKey-1]+rowKey] ? <div>
                                                            <p className="preview-qa-header">Question</p>
                                                            <p>{questions[columnID[columnKey-1]+rowKey].question}</p>
                                                            </div>: <p></p> }
                                                        </Col>
                                                        <Col xs="6">
                                                        {questions[columnID[columnKey-1]+rowKey] ? <div>
                                                            <p className="preview-qa-header">Answer</p>
                                                            <p>{questions[columnID[columnKey-1]+rowKey].answer}</p>
                                                            </div>: <p></p> }
                                                        </Col>
                                                    </Row>
                                                </Container>
                                            </div>
                                            :
                                            <p>{points[rowKey]}</p>}
                                        
                                        </div>
                                }
                                )}
                            </div>

                        }
                        )}
                    </div>
                </div>
                              {/* GAME BOARD END*/}
            </div>
        )
    
}

export default EditJeopardy;