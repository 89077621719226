// src/Analytics.js
import ReactGA from 'react-ga4';

const TRACKING_ID = "G-TLE0SQKN6T"; // Your Google Analytics Tracking ID

export const initGA = () => {
  ReactGA.initialize(TRACKING_ID);
};

export const logPageView = () => {
  ReactGA.send({ hitType: "pageview", page: window.location.pathname });
};
