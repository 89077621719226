import React, { useState, useContext,useCallback, useEffect, useRef} from 'react';
import { useParams } from "react-router-dom";

import circle_icon from './assets/circle.png';
import cross_icon from './assets/cross.png'
import green_check from '../../assets/images/green_check.png'
import red_cross from '../../assets/images/red_cross.png'

import { Container, Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import API from '../../utils/API';
import './style.css';
import bubbleSoundFile from './assets/sounds/bubble_sound.mp3'; // Update the path
import wistfulSoundFile from './assets/sounds/wistful.mp3'; // Update the path
import slimeSoundFile from './assets/sounds/slime.mp3'; // Update the path

const calculateWinner = (squares) => {
    const lines = [
      [0, 1, 2],
      [3, 4, 5],
      [6, 7, 8],
      [0, 3, 6],
      [1, 4, 7],
      [2, 5, 8],
      [0, 4, 8],
      [2, 4, 6],
    ];
    for (let i = 0; i < lines.length; i++) {
      const [a, b, c] = lines[i];
      if (squares[a] && squares[a] === squares[b] && squares[a] === squares[c]) {
        return { winner: squares[a], line: [a,b,c] };
      }
    }
    return { winner: null, line: null };
  };
function shuffleArray(array) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1)); // Random index from 0 to i
        [array[i], array[j]] = [array[j], array[i]]; // Swap elements
    }
    return array;
  }

const TicTacToe =(props) => {
    const {id} = useParams();
    const [questionSet,setQuestionSet] = useState({});
    const [questions,setQuestions] = useState([]);
    const [currentQuestion, setCurrentQuestion] = useState('');
    const [board, setBoard] = useState(Array(9).fill(""));
    const [isXNext, setIsXNext] = useState(true);
    const [modal, setModal] = useState(false);
    const [currentBox, setCurrentBox] = useState(null);
    const [questionsPool, setQuestionsPool] = useState([...questions]);
    const [xScore, setXScore] = useState(0);
    const [oScore, setOScore] = useState(0);
    const [gameEnded, setGameEnded] = useState(false);
    const [winningLine, setWinningLine] = useState([]);
    const toggleModal = () => setModal(!modal);

  //full screen handler
  const elementRef = useRef(null);
  const [fullscreen, setFullScreen] = useState(false)
  const handleFullscreen = () => {
    if (!document.fullscreenElement) {
      // Enter fullscreen
      if (elementRef.current.requestFullscreen) {
        elementRef.current.requestFullscreen();
      } else if (elementRef.current.mozRequestFullScreen) { /* Firefox */
        elementRef.current.mozRequestFullScreen();
      } else if (elementRef.current.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
        elementRef.current.webkitRequestFullscreen();
      } else if (elementRef.current.msRequestFullscreen) { /* IE/Edge */
        elementRef.current.msRequestFullscreen();
      }
      setFullScreen(true)
    } else {
      // Exit fullscreen
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) { /* Firefox */
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) { /* Chrome, Safari and Opera */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) { /* IE/Edge */
        document.msExitFullscreen();
      }
      setFullScreen(false)

    }
  };

    useEffect(()=>{
    API.getQuestionSet(id).then(res=>{
        setQuestionSet(res.data);
        const shuffledQuestions = shuffleArray(res.data.Questions); // Use slice to avoid mutating the original array
        let cleanedQuestions = []
        for (let i = 0; i < shuffledQuestions.length; i++) {
          if(shuffledQuestions[i].question){
            cleanedQuestions.push(shuffledQuestions[i])
          } 
        }
        API.addPlayCount(id)
        setQuestions(cleanedQuestions)
        setQuestionsPool(cleanedQuestions)
    }).catch(err=>{
        console.log(err)
    })
    },[])

//full screen handler end 
            //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~HANDLE AUDIO TOGGLE START~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

            const [isMuted, setIsMuted] = useState(false);
            const [volume, setVolume] = useState(1); // Volume ranges from 0.0 to 1.0   
            const playSound = (soundFile, volume) => {
              const audio = new Audio(soundFile);
              audio.volume = volume; // Set the volume
              audio.play();
            };  
                     
            const playSlimeSoundFile = () => {
              if (!isMuted) {
                playSound(slimeSoundFile, volume);
              }
            }; 
            const playBubbleSound = () => {
              if (!isMuted) {
                playSound(bubbleSoundFile, volume);
              }
            };
          
            const playWistfulSound = () => {
              if (!isMuted) {
                playSound(wistfulSoundFile, volume);
              }
            };
          
            const changeVolume = (event) => {
                const volumeValue = event.target.value;
                setVolume(volumeValue);
                if (volumeValue == 0) {
                  setIsMuted(true);
                } else {
                  setIsMuted(false);
                }
              };
              const toggleMute = () => {
                setIsMuted(!isMuted);
              };
            //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~HANDLE AUDIO TOGGLE END~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

            //~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~TIMER FOR JEOPARDY GAME  && COUNTDOWN START~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~

    const timeoutRef = useRef(null);
    const [modalCss, setModalCss]= useState();
    const [modalExpand, setModalExpand] = useState(false);
    const [showAnswer, setShowAnswer] = useState(false);
    const [showContent, setShowContent] = useState(false);

    const handleClick = (e,index) => {
      const { winner, line } = calculateWinner(board);
      if (board[index] || winner) {
        return;
      }
        setCurrentQuestion('');
        setShowContent(false);
        setCurrentBox(index);
        setShowAnswer(false);
        setModalExpand(false);
        setModalCss({top: e.clientY+"px", left: e.clientX+"px", background:'#1f3540'});

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
          }
          timeoutRef.current = setTimeout(() => {
            setModalExpand(true);
          }, 50);    

        toggleModal();
        setTimeout(() => {
          setShowContent(true);
      }, 100); // Adjust this timeout to match the CSS transition duration
      let questionsPoolTemp = [...questionsPool]
      if (questionsPoolTemp.length > 0) {
        setCurrentQuestion(questionsPool[0]);
        setQuestionsPool(questionsPool.slice(1));
      }              
        
    };
    
    const onShowAnswer = ()=>{
      setShowAnswer(!showAnswer);
    }

    useEffect(() => {
      if (questionsPool.length === 0 && questions.length > 0) {
          setQuestionsPool([...questions]);
      } 
    }, [questionsPool, questions]);

    const handleMove = () => {
        playBubbleSound();
        const newBoard = board.slice();
        newBoard[currentBox] = isXNext ? "X" : "O";
        setBoard(newBoard);
        setIsXNext(!isXNext);
        toggleModal();
        const { winner, line } = calculateWinner(newBoard);
        if (winner === 'X') {
          setGameEnded(true);
          setWinningLine(line);
          playWistfulSound();
          setXScore((prevScore) => prevScore + 1);
        } else if (winner === 'O') {
          setGameEnded(true);
          setWinningLine(line);
          playWistfulSound();
          setOScore((prevScore) => prevScore + 1);
        }
        checkGameEnded(newBoard);

    };
    const checkGameEnded = (board) => {
      const isBoardFull = board.every(box => box !== '');
      if (isBoardFull) {
        setGameEnded(true);
      }
    };
  
    const [boxNumber] = useState({0:'1',1:'4',2:'7',3:'2',4:'5',5:'8',6:'3',7:'6',8:'9'});
    const renderSquare = (index) => {
        
        return (
          <div className={`ttt-boxes justify-content-center vertical-center titan-one-regular text-white ${winningLine.includes(index) ? 'ttt-winning-box' : ''}` } onClick={(e) => handleClick(e,index)}>
            {
            board[index] === "X" ? (
                <img src={cross_icon} alt="X" />
            ) : board[index] === "O" ? (
                <img src={circle_icon} alt="O" />
            ) : (
                <h1>{boxNumber[index]}</h1>
            )
            }
          </div>
        );
    }
    const correctAnswer=()=>{
      handleMove()
    }
    const incorrectAnswer=()=>{
      playSlimeSoundFile();
      setIsXNext(!isXNext);
      toggleModal();
  }
    const onNewGameClick =()=>{
      setGameEnded(false);
      setWinningLine([]);
      setBoard(Array(9).fill(""));
    }
    return (
        <div ref={elementRef} className="background-blue-95vh blue-background ">
                <div>

                <div className="controls-container">
                    <p onClick={handleFullscreen} className="full-screen-button z-3">
                        {fullscreen ? <i className="fa-solid fa-minimize"/> : <i className="fa-solid fa-maximize"/>}
                    </p>
                    <p onClick={toggleMute} className="mute-button z-3">
                        {isMuted || volume === 0 ? <i className="fa-solid fa-volume-mute" style={{color:'rgb(69 0 117)'}}/> : <i className="fa-solid fa-volume-high"/>}
                    </p>
                    <input
                        type="range"
                        min="0"
                        max="1"
                        step="0.01"
                        value={isMuted ? 0 : volume}
                        onChange={changeVolume}
                        style={{ marginLeft: '10px' }}
                        className="volume-slider z-3"
                        aria-label="Volume"
                    />
                </div>
                </div>

                <Container className="ttt-game-container" >
                    <Row className=" titan-one-regular h-100 text-center">
                      <Col xs='12'>
                          <h1 className='ttt-title'>{questionSet.title}</h1>
                    </Col>

                      <Col xs="3"> 
                      <div className={`ttt-score-container`}>
                      <img className={`ttt-score ${isXNext&&!gameEnded ? 'next-turn' : ''}`} src={cross_icon} alt="X" />
                      </div>
                      <h1>{xScore}</h1>
                      </Col>

                      <Col xs="6 " className='vertical-center d-flex justify-content-center align-items-center'>
                        <div className='ttt-container'>
                                <div className='ttt-board'>
                                    <div className='ttt-row1'>
                                        {renderSquare(0)}
                                        {renderSquare(1)}
                                        {renderSquare(2)}
                                    </div>
                                    <div className='ttt-row2'>
                                        {renderSquare(3)}
                                        {renderSquare(4)}
                                        {renderSquare(5)}
                                    </div>
                                    <div className='ttt-row3'>
                                        {renderSquare(6)}
                                        {renderSquare(7)}
                                        {renderSquare(8)}
                                    </div>

                                </div>
                        </div>
                        </Col>
                        <Col xs="3">
                        <div className={`ttt-score-container`}>
                        <img  className={`ttt-score ${!isXNext&&!gameEnded ? 'next-turn' : ''}`} src={circle_icon} alt="O" />
                        </div>
                        <h1>{oScore}</h1>
                        </Col>
                        <Col xs='12'>
                        {gameEnded?
                        <button className="btn show-answer-btn" onClick={onNewGameClick}>New Game</button>
                          :
                          ''
                        }
                        </Col>
                    </Row>

                </Container>
                {modal ?           
                <div className="jeopardy-modal-container" >
                    
                    <div className={"jeopardy-modal text-center " + (modalExpand? "jeopardy-modal-expand":"") }style={modalCss} onClick={e=>e.stopPropagation()} >
                    <div className='modal-button-container-top'>
                    {isXNext?
                          <img className='ttt-score' src={cross_icon} alt="X" />
                          :
                          <img  className='ttt-score' src={circle_icon} alt="O" />
                    }
                    </div>
                      {showContent?
                        <div className="d-flex flex-column justify-content-center align-items-center h-100">

                          {showAnswer?
                            <div className="m-3 nunito game-modal-text">
                              <p>{currentQuestion.answer}</p>
                            </div>
                          :
                            <div>
                              {currentQuestion.image && <img className="game-modal-image" src={currentQuestion.image} alt="Image description" />}
                              <div className="m-3 nunito game-modal-text">
                                <p>{currentQuestion.question}</p>
                              </div>
                            </div>

                          }

                        <div className="jeopardy-button-container titan-one-small">
                          <div className=" ttt-check-container titan-one-small">
                                <img src={green_check}className='ttt-check' onClick={()=>correctAnswer()}></img>
                                <img src={red_cross} className='ttt-check' onClick={()=>incorrectAnswer()}></img>
                          </div>

                          {showAnswer?
                              <button className="btn show-answer-btn" onClick={()=>onShowAnswer()}>Show Question</button>
                            :
                              <button className="btn show-answer-btn" onClick={()=>onShowAnswer()}>Show Answer</button>
                          }
                        </div>

                        </div>
                        :""
                      }
                    </div>
                </div>
                :""
                }
        </div>

    );
  
      
}

export default TicTacToe;
