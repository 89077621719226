import React, { useState, useEffect,useContext } from 'react';
import { Container, Row, Col, Card, Input, Button,Label } from 'reactstrap'; // Assuming you're using reactstrap
import API from '../../utils/API'; // Adjust the import according to your project structure
import { Link,useNavigate} from 'react-router-dom'; // Import Link for navigation
import { AuthContext } from '../../components/AuthContext/AuthContext';
 
const Signup = () => {
  const {  login } = useContext(AuthContext);
  const navigate = useNavigate(); // Initialize useNavigate
  const [formData, setFormData] = useState({
    username: "",
    password: "",
    name: "",
    email: "",
    confirmPassword: "",
    verificationCode: "" 
  });
  const [validation, setValidation] =useState({
    username:false,
    password:false,
    name: false,
    email: false,
    confirmPassword: false,
    verificationCode: false 
  });
  const [apiError, setApiError] = useState(null);
  const [errors, setErrors] = useState({  
    username: null,
    password: null,
    name: null,
    email: null,
    confirmPassword: null,
    verificationCode: null
  })
  const [isVerificationSent, setIsVerificationSent] = useState(false); // State to track if the verification code has been sent
  const [isVerified, setIsVerified] = useState(false); // State to track if the verification code has been sent
  const [resendAvailable, setResendAvailable] = useState(true);
  const [resendTimer, setResendTimer] = useState(0);
  const [isButtonDisabled, setIsButtonDisabled] = useState({
    emailSubmit:false
  });

  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value
    });
    if (name === "email") {
      validateEmail(value);
    } else if (name === "username") {
      validateUsername(value);
    } else if (name === "password") {
      validatePassword(value);
    } else if (name === "confirmPassword") {
      validateConfirmPassword(value);
    } else if (name === "verificationCode"){
      validateVerificationCode(value)
    }else if (name === "name"){
      validateName(value)
    }

  };

  const handleEmailSubmit = event => {
    if(event){
      event.preventDefault();
    }
    if(validation.email){
      let email=formData.email
      setErrors((prev) => ({
        ...prev,
        email: ""
      }));
      setIsButtonDisabled((prev) => ({
        ...prev,
        emailSubmit: true
      }));
      API.sendEmailVerification({ email })
      .then(res => {
        console.log(res);
        setIsVerificationSent(true);
        setResendAvailable(false);
        setResendTimer(60); // Set timer for 60 seconds

      })
      .catch(err => {
        console.log(err)
        if(err.response.data){
          setErrors((prev) => ({
            ...prev,
            email:err.response.data
          }));
        }
      }).finally(setTimeout(() => setIsButtonDisabled((prev) => ({
        ...prev,
        emailSubmit: false
      })), 3000))
    } else {
      setErrors((prev) => ({
        ...prev,
        email: "Please enter a valid email address."
      }));
    }
  };

  const handleVerificationSubmit = event => {
    event.preventDefault();
    if(validation.verificationCode){
        setErrors((prev) => ({
          ...prev,
          verificationCode: ""
        }));
        API.verifyEmailCode({ email: formData.email, code: formData.verificationCode })
          .then(res => {
            setIsVerified(true);
          })
          .catch(err => {
            console.log(err.response.data)
            if(err.response.data){
              setErrors((prev) => ({
                ...prev,
                verificationCode:err.response.data
              }));
            }
          });
    } else {
      setErrors((prev) => ({
        ...prev,
        verificationCode: "Verification code must be 6 characters long."
      }));
    }
  };
  const handleFormSubmit = event => {
    if(event){
      event.preventDefault();
    }
    setApiError(null);
    if(validation.email && validation.password && validation.confirmPassword && validation.username){
      API.signUp( formData )
      .then(res => {
        login(res.data.user)
        navigate('/dashboard');
      })
      .catch(err => {
        console.log(err)
        setApiError(err.response.data.message)
      });
    } else {
      let newErrors = {}
      if(!validation.username){
        newErrors.username =  "Username must be between 3 and 15 characters long.";
      }
      if(!validation.name){
        newErrors.name =  "Name must be at least 2 characters long.";
      }
      if(!validation.password){
        newErrors.password =  "Password must be at least 6 characters long.";
      }
      if(!validation.confirmPassword){
        newErrors.confirmPassword =   "Passwords do not match.";
      }   
      setErrors(newErrors);
 
    }
  };

  useEffect(() => {
    let timer;
    if (resendTimer > 0) {
      timer = setInterval(() => {
        setResendTimer(prev => prev - 1);
      }, 1000);
    }
    if (resendTimer === 0 && !resendAvailable) {
      setResendAvailable(true);
    }
    return () => clearInterval(timer);
  }, [resendTimer, resendAvailable]);


  useEffect(() => {
    const initializeGoogleSignUp = () => {
      if (window.google && window.google.accounts) {
        window.google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
          callback: handleCredentialResponse
        });

        window.google.accounts.id.renderButton(
          document.getElementById("g_id_button"),
          { theme: "outline", size: "large" }  // Customize button appearance
        );

        window.google.accounts.id.prompt(); // Show the One Tap UI
      } else {
        console.error("Google Identity Services not loaded.");
      }
    };

    // Function to dynamically load Google Identity Services script
    const loadGoogleScript = () => {
      const script = document.createElement('script');
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      script.defer = true;
      script.onload = () => {
        initializeGoogleSignUp();
      };
      document.body.appendChild(script);
    };

    loadGoogleScript();
  }, []);

  // Handle the credential response
  const handleCredentialResponse = (response) => {
    const idToken = response.credential;
    console.log("Credential Response:", {idToken});
    API.googleAuth({idToken}).then(res=>{
      login(res.data.user)
      navigate('/dashboard');
    }).catch(err=>{
      console.log(err)

    })
  };
  const handleResendClick = () => {
    if (resendAvailable) {
      handleEmailSubmit(); // Trigger the resend by calling the form submit function
    }
  };

  const validateEmail = (email) => {
    setIsButtonDisabled((prev) => ({
      ...prev,
      emailSubmit: false
    }))
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setValidation((prev) => ({
      ...prev,
      email: emailRegex.test(email)
    }));
  };

  const validateUsername = (username) => {
    // Username should be at least 3 characters long
    const isValid = username.trim().length >= 3 && username.trim().length<=15;
    setValidation((prev) => ({
      ...prev,
      username: isValid
    }));
  };
  const validateName = (name) => {
    // Username should be at least 3 characters long
    const isValid = name.trim().length >= 2 ;
    setValidation((prev) => ({
      ...prev,
      name: isValid
    }));
  };

  const validatePassword = (password) => {
    // Password should be at least 6 characters long
    const isValid = password.length >= 6;
    setValidation((prev) => ({
      ...prev,
      password: isValid
    }));
  };

  const validateConfirmPassword = (confirmPassword) => {
    // Confirm password should match password
    const isValid = confirmPassword === formData.password;
    setValidation((prev) => ({
      ...prev,
      confirmPassword: isValid
    }));
  };
  const validateVerificationCode = (verificationCode) => {
    // Confirm password should match password
    const isValid = verificationCode.length == 6;
    setValidation((prev) => ({
      ...prev,
      verificationCode: isValid
    }));
  };

  return (
    <div className="main-background vertical-center">
      <Container>
        <Row className="h-100">
          <Col lg="3"></Col>
          {isVerified?
                  <Col lg="6" className="align-self-center">
                      <Card>
                          <Container>
                              <h3 className="text-align-center titan-one-regular">
                                    Sign Up
                              </h3>
                              {apiError && <p style={{ color: 'red', margin: '5px 0' }}>{apiError}</p>}
                              <Label className='titan-one-regular' style={{margin:'6px'}}>Username:</Label>
                              <Input
                                type="text"
                                value={formData.username}
                                onChange={handleInputChange}
                                name="username"
                                placeholder="Username"
                                required
                              />
                              {errors.username && <p style={{ color: 'red', margin: '5px 0' }}>{errors.username}</p>}
                              <Label className='titan-one-regular' style={{margin:'6px'}}>Name:</Label>
                              <Input
                                type="text"
                                value={formData.name}
                                onChange={handleInputChange}
                                name="name"
                                placeholder="Name"
                                required
                              />
                              {errors.name && <p style={{ color: 'red', margin: '5px 0' }}>{errors.name}</p>}
                              <Label className='titan-one-regular' style={{margin:'6px'}}>Password:</Label>
                              <Input
                                type="password"
                                value={formData.password}
                                onChange={handleInputChange}
                                name="password"
                                placeholder="Password"
                                required
                              />
                              {errors.password && <p style={{ color: 'red', margin: '5px 0' }}>{errors.password}</p>}
                              <Label className='titan-one-regular' style={{margin:'6px'}}>Confirm Password:</Label>
                              <Input
                                type="password"
                                value={formData.confirmPassword}
                                onChange={handleInputChange}
                                name="confirmPassword"
                                placeholder="Confirm Password"
                                required
                              />
                              {errors.confirmPassword && <p style={{ color: 'red', margin: '5px 0' }}>{errors.confirmPassword}</p>}

                                <Row style={{ marginTop: '20px', marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                                  <Col xs='auto'>
                                    <button
                                      type="submit"
                                      className="btn btn-rounded nav-btn-light-purple signup-button titan-one-small"
                                      onClick={handleFormSubmit}
                                    >
                                      Sign Up
                                    </button>
                                  </Col>
                                  <Col xs='auto'>
                                    <p style={{ marginLeft: '20px' , marginBottom:'0px'}}>
                                      Already have an account? <Link to="/login">Login here</Link>
                                    </p>
                                  </Col>
                                </Row>
                          </Container>
                        </Card>


                  </Col>
                  :
        
            <Col lg="6" className="align-self-center">
            {isVerificationSent?
                <Card>
                  <Container>
                    <Row>
                      <Col xs='12'>
                      <h3 className="text-align-center titan-one-regular">
                      Verify Your Email
                      </h3>
                      <p>We have sent a verification code to {formData.email}. Please enter the code below to verify your email.</p>
                      </Col>
                    </Row>
                    <Row>
                        <Col xs='12'>

                          <Input
                            type="text"
                            value={formData.verificationCode}
                            onChange={handleInputChange}
                            name="verificationCode"
                            placeholder="Verification Code"
                            required
                          />
                          {errors.verificationCode && <p style={{ color: 'red', margin: '5px 0' }}>{errors.verificationCode}</p>}

                        </Col>
                      </Row>
                      <Row style={{marginTop:'20px'}}>
                        <Col xs='12'>
                        <button
                        type="submit"
                        className="btn btn-rounded nav-btn-light-purple signup-button titan-one-small"
                        onClick={handleVerificationSubmit}
                      >
                        Submit
                      </button>
                      <p style={{ marginTop: '10px' }}>
                        {resendAvailable ? (
                          <a href="#" onClick={handleResendClick}>Resend Code</a>
                        ) : (
                          <span>Resend available in {resendTimer} seconds</span>
                        )}
                      </p>

                        </Col>
                      </Row>

                  </Container>
                </Card>
          
              :
              <Card>
                <Container>
                <h3 className="text-align-center titan-one-regular">
                      Sign Up
                </h3>

                  {/* Google Sign-In Button */}
                  <Row className='text-center'>
                  <Col>
                  <div style={{display:'inline-block'}}>
                      <div id="g_id_button"></div>
                      </div>

                  </Col>
                  
                    </Row>
                  {/* Divider Line with "or" */}
                  <Row className='text-center'>
                    <Col>
                      <div style={{ position: 'relative', margin: '20px 0' }}>
                        <hr style={{ borderColor: '#ddd' }} />
                        <span className='nunito'style={{ position: 'absolute', left: '50%', top: '-10px', background: '#fff', padding: '0 10px', transform: 'translateX(-50%)', color:'#ddd' }}>or</span>
                      </div>
                    </Col>
                  </Row>

                    <Label className='titan-one-regular' style={{marginLeft:'6px'}}>Email:</Label>
                    <Input
                      type="email"
                      value={formData.email}
                      onChange={handleInputChange}
                      name="email"
                      placeholder="Email"
                      required
                    />
                      {errors.email && <p style={{ color: 'red', margin: '5px 0' }}>{errors.email}</p>}
                      <Row style={{ marginTop: '20px', marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
                        <Col xs='auto'>
                          <button
                            type="submit"
                            className="btn btn-rounded nav-btn-light-purple signup-button titan-one-small"
                            onClick={handleEmailSubmit}
                            disabled={isButtonDisabled.emailSubmit} 
                          >
                            Sign Up
                          </button>
                        </Col>
                        <Col xs='auto'>
                          <p style={{ marginLeft: '20px' , marginBottom:'0px'}}>
                            Already have an account? <Link to="/login">Login here</Link>
                          </p>
                        </Col>
                      </Row>
                </Container>
              </Card>

            }
            </Col>
          }
          <Col lg="3"></Col>
        </Row>
      </Container>
    </div>
  );
};

export default Signup;
