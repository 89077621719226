import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import './style.css'; // Assuming you have a separate CSS file for styling

function shuffleObject(obj) {
  const nonEmptyAnswers = Object.values(obj).filter(answer => answer.answer.trim() !== "");
  const emptyAnswers = Object.values(obj).filter(answer => answer.answer.trim() === "");

  for (let i = nonEmptyAnswers.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [nonEmptyAnswers[i], nonEmptyAnswers[j]] = [nonEmptyAnswers[j], nonEmptyAnswers[i]];
  }

  const combinedAnswers = [...nonEmptyAnswers, ...emptyAnswers];

  const shuffledObject = {};
  combinedAnswers.forEach((answer, index) => {
    shuffledObject[`answer${index + 1}`] = answer; 
  });

  return shuffledObject;
}

const QuizModal = ({ isOpen, onAnswer, question }) => {
  const [showFeedback, setShowFeedback] = useState(false);
  const [isCorrect, setIsCorrect] = useState(false);
  const [shuffledAnswers, setShuffledAnswers] = useState(null);
  const [canProceed, setCanProceed] = useState(false);
  const [timer, setTimer] = useState(3);
  const [initialOverlayVisible, setInitialOverlayVisible] = useState(false);

  useEffect(() => {
    if (isOpen && question) {
      const shuffled = shuffleObject(question.multiAnswers);
      setShuffledAnswers(shuffled);
      setShowFeedback(false);
      setCanProceed(false); 
      setTimer(3); 
      setInitialOverlayVisible(true); 

      const overlayTimeout = setTimeout(() => {
        setInitialOverlayVisible(false);
      }, 500);

      return () => clearTimeout(overlayTimeout);
    }
  }, [isOpen, question]);

  useEffect(() => {
    let countdown;
    if (showFeedback && !canProceed && !isCorrect) {
      countdown = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            setCanProceed(true); 
            clearInterval(countdown);
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    } else if (isCorrect) {
      setCanProceed(true);
    }

    return () => clearInterval(countdown); 
  }, [showFeedback, canProceed]);

  if (!isOpen || !shuffledAnswers) return null;

  const handleAnswer = (correctAnswer, answerText) => {
    if (!showFeedback && answerText.trim() !== "") {
      setIsCorrect(correctAnswer === "true");
      setShowFeedback(true);
    }
  };

  const handleProceed = () => {
    if (canProceed) {
      onAnswer(isCorrect ? "true" : "false");
      setIsCorrect(null);
      setShowFeedback(false);
      setCanProceed(false);
      setTimer(3); 
    }
  };

  const modalClass = showFeedback
    ? isCorrect
      ? 'quiz-modal-content bg-success'
      : 'quiz-modal-content bg-danger'
    : 'quiz-modal-content';

  return (
    <div className="modal-overlay">
      <div className="quiz-modal-dialog">
        <div className={modalClass + ' nunito'}>
          <>
            <Container className='h-50 justify-content-center align-items-center' fluid>
              {showFeedback ? (
                <Row className='h-100 justify-content-center align-items-center'>
                  <Col xs="12" className="d-flex flex-column justify-content-center align-items-center">
                    <h1 className='titan-one-regular'>{isCorrect ? 'Correct!' : 'Incorrect!'}</h1>
                    {canProceed ? (
                      <p>Click to proceed</p>
                    ) : (
                      <p>Wait {timer} second{timer > 1 ? 's' : ''} to proceed</p>
                    )}
                  </Col>
                </Row>
              ) : (
                <Row className='h-100 justify-content-center align-items-center'>
                  {question.image && (
                    <Col xs="12" md="6" className="d-flex justify-content-center align-items-center question-modal-image">
                      <img className="quiz-card-image" src={question.image} alt="Question Image" />
                    </Col>
                  )}
                  <Col xs="12" md="6" className="d-flex flex-column justify-content-center align-items-center question-modal-question">
                    <h3>{question.question}</h3>
                  </Col>
                </Row>
              )}
            </Container>

            {/* row for buttons */}
            <Container className='h-50' fluid>
              <Row className="h-50 text-wrap">
                {shuffledAnswers.answer1 && (
                  <Col xs="12" sm="6" className='p-1'>
                      {showFeedback && shuffledAnswers.answer1.correctAnswer=== "false" ? null : (
                        <button 
                          className={`btn btn-light-blue button-size kp-question-btn ${!shuffledAnswers.answer1.answer.trim() ? 'no-hover' : ''}`} 
                          onClick={() => handleAnswer(shuffledAnswers.answer1.correctAnswer, shuffledAnswers.answer1.answer)}
                        >
                          <h3>{shuffledAnswers.answer1.answer}</h3> 
                        </button>
                      )}
                  </Col>
                )}
                {shuffledAnswers.answer2 && (
                  <Col xs="12" sm="6" className='p-1'>
                  {showFeedback && shuffledAnswers.answer2.correctAnswer=== "false" ? null : (
                    <button 
                      className={`btn btn-light-red button-size kp-question-btn ${!shuffledAnswers.answer2.answer.trim() ? 'no-hover' : ''}`} 
                      onClick={() => handleAnswer(shuffledAnswers.answer2.correctAnswer, shuffledAnswers.answer2.answer)}
                    >
                      <h3>{shuffledAnswers.answer2.answer}</h3> 
                    </button>
                   )}
                  </Col>
                )}
              </Row>
              <Row className="h-50">
                {shuffledAnswers.answer3 && (
                  <Col xs="12" sm="6" className='p-1'>
                    {showFeedback && shuffledAnswers.answer3.correctAnswer=== "false" ? null : (
                      <button 
                        className={`btn btn-light-yellow button-size kp-question-btn ${!shuffledAnswers.answer3.answer.trim() ? 'no-hover' : ''}`} 
                        onClick={() => handleAnswer(shuffledAnswers.answer3.correctAnswer, shuffledAnswers.answer3.answer)}
                      >
                        <h3>{shuffledAnswers.answer3.answer}</h3> 
                      </button>
                    )}
                  </Col>
                )}
                {shuffledAnswers.answer4 && (
                  <Col xs="12" sm="6" className='p-1'>
                  {showFeedback && shuffledAnswers.answer4.correctAnswer=== "false" ? null : (
                    <button 
                      className={`btn btn-light-green button-size kp-question-btn ${!shuffledAnswers.answer4.answer.trim() ? 'no-hover' : ''}`} 
                      onClick={() => handleAnswer(shuffledAnswers.answer4.correctAnswer, shuffledAnswers.answer4.answer)}
                    >
                      <h3>{shuffledAnswers.answer4.answer }</h3> 
                    </button>
                    )}
                  </Col>
                )}
              </Row>
            </Container>
          </>
          {/* Initial invisible overlay */}
          {initialOverlayVisible && (
            <div className="invisible-overlay"></div>
          )}

          {/* Invisible Overlay */}
          {showFeedback && (
            <div 
              className="invisible-overlay" 
              style={{ cursor: canProceed ? 'pointer' : 'default' }}  
              onClick={handleProceed}>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuizModal;
