import Phaser from 'phaser';
//background 2
import gameBackground from '../assets/game_background_1/game_background_1.png';
import clouds1 from '../assets/game_background_3/layers/clouds_1.png';
import clouds2 from '../assets/game_background_3/layers/clouds_2.png';
import ground1 from '../assets/game_background_3/layers/ground_1.png';
import ground2 from '../assets/game_background_3/layers/ground_2.png';
import ground3 from '../assets/game_background_3/layers/ground_3.png';
import plant from '../assets/game_background_3/layers/plant.png';
import rocks from '../assets/game_background_3/layers/rocks.png';
import sky from '../assets/game_background_3/layers/sky.png';


import PinkMonsterRun from '../assets/Pink_Monster_Run_6.png';
import PinkMonsterJump from '../assets/Pink_Monster_Jump_8.png';
import pizza from '../assets/pizza.png'; 
import iceCream from '../assets/ice_cream.png';
import choco from '../assets/choco.png';
import goldenapple from '../assets/golden_apple.png'
import strawberry from '../assets/strawberry.png'
import Player from '../classes/Player';
import PlatformManager from '../classes/PlatformManager'; // Import the PlatformManager
import Pizza from '../classes/Pizza'; // Import the Pizza class
import GoldenApple from '../classes/GoldenApple'; // Import the GoldenApple class
import Strawberry from '../classes/Strawberry'; // Import the strawberry class
import Choco from '../classes/Choco'; // Import the Choco class
import IceCream from '../classes/IceCream'; // Import the IceCream class

import basicTop from '../assets//Corruption/Basic_Top.png';

//import sounds
import jumpSound from '../assets/Sounds/Jump.ogg'
import collectSound from '../assets/Sounds/Collect.ogg'
import jumpSoundMp3 from '../assets/Sounds/Jump.mp3'
import collectSoundMp3 from '../assets/Sounds/Collect.mp3'


let gameOptions = {
    platformStartSpeed: 350,
    spawnRange: [50, 350],
    platformSizeRange: [200, 650],
    playerGravity: 900,
    jumpForce: 400,
    playerStartPosition: 200,
    jumps: 2,
    itemSpawnRate: 2000, 
    playerSpeed:1
};

export default class GameState extends Phaser.Scene {
    constructor() {
        super({ key: 'GameState' });
        this.backgroundLayers = [];
        this.gameOptions = gameOptions; // Set gameOptions directly from the variable
        this.score = 0; // Initialize score
        this.distanceTraveled = 0; // To track how far the player has run
        this.soundOn = true; // Track the sound status

    }

    preload() {
        this.load.image("platform", basicTop);
        //bg2
        this.load.image('game_background', gameBackground);
        this.load.image('clouds_1', clouds1);
        this.load.image('clouds_2', clouds2);
        this.load.image('ground_1', ground1);
        this.load.image('ground_2', ground2);
        this.load.image('ground_3', ground3);
        this.load.image('plant', plant);
        this.load.image('rocks', rocks);
        this.load.image('sky', sky);

        this.load.spritesheet('player', PinkMonsterRun, {
            frameWidth: 32,  
            frameHeight: 32, 
            endFrame: 5      // Load frames 0-9 (10 frames total)
        });        
        this.load.spritesheet('playerJump', PinkMonsterJump, {
            frameWidth: 32,  
            frameHeight: 32, 
            endFrame: 7 // Load frames 0-7 (8 frames total)
        });        
        this.load.image('pizza', pizza);
        this.load.image('choco', choco);
        this.load.image('iceCream', iceCream);
        this.load.image('goldenapple', goldenapple);
        this.load.image('strawberry', strawberry);
        // Preload sounds
        this.load.audio('jumpSound', [jumpSound,jumpSoundMp3]);
        this.load.audio('collectSound', [collectSound,collectSoundMp3]);

    }

    create() {
        this.resetGameOptions();

            const layerNames = ['sky', 'rocks', 'clouds_1', 'clouds_2', 'ground_1', 'ground_2', 'ground_3', 'plant'];
            const layerSpeeds = [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7];
    
        layerNames.forEach((layerName, index) => {
            for (let i = 0; i < 2; i++) {
                const layer = this.add.image(i * this.cameras.main.width, 0, layerName).setOrigin(0, 0);
                layer.setDisplaySize(this.cameras.main.width, this.cameras.main.height);
                layer.speed = layerSpeeds[index]*this.gameOptions.playerSpeed;
                this.backgroundLayers.push(layer);
            }
        });

        // Add the sound toggle button in the top-right corner
        this.soundButton = this.add.image(1200, 30, this.soundOn ? 'sound' : 'silent').setInteractive(); // Set texture based on sound state
        this.soundButton.setScale(0.7); // Scale down if needed

        // Toggle sound on button click
        this.soundButton.on('pointerdown', () => {
            this.toggleSound();
        });
    
    
        // Initialize PlatformManager
        this.platformManager = new PlatformManager(this);
    
        // Add the first platform
        this.platformManager.addPlatform(this.game.config.width, this.game.config.width / 2);
    
        // Initialize the player
        this.player = new Player(this, gameOptions.playerStartPosition, this.game.config.height / 2);

        // Setting collisions between the player and the platform group
        this.physics.add.collider(this.player, this.platformManager.platformGroup);

        // Create a group for items
        this.itemGroup = this.add.group({
            // removeCallback: function(item) {
            //     item.scene.itemPool.add(item);
            // },
            // runChildUpdate: true // Enables the update method for child objects
        });
        
        // Pool for recycled items
        // this.itemPool = this.add.group({
        //     removeCallback: function(item) {
        //         item.scene.itemGroup.add(item);
        //     }
        // });

        // Call the method to start spawning items
        // Inside the create function of your scene
        this.spawnEvent = this.time.addEvent({
            delay: this.gameOptions.itemSpawnRate,
            callback: this.spawnItem,
            callbackScope: this,
            loop: true
        });
        // First score text
        this.scoreText = this.add.text(16, 16, 'Score: 0', {
            fontSize: '32px',
            fill: '#fff',
            fontFamily: 'Arial',
            backgroundColor: 'rgba(0, 0, 0, 0)' // Transparent background
        })


    // Create energy bar background (gray bar) and move it below the score
    this.energyBarBg = this.add.graphics();
    this.energyBarBg.fillStyle(0x808080, 1); // Gray background
    this.energyBarBg.fillRect(16, 60, 200, 15); // Positioned under the score text

    // Create energy bar (orange)
    this.energyBar = this.add.graphics();
    this.energyBar.fillStyle(0xE6900D, 1); // Orange color (RGB 230, 144, 13)
    this.energyBar.fillRect(16, 60, 200, 15); // Full energy bar, below the score

    // Energy settings
    this.maxEnergy = 200; // Maximum energy (matches the width of the bar)
    this.currentEnergy = this.maxEnergy; // Start with full energy
    this.energyDepletionRate = 0.2; // Decrease energy per frame

    if(!this.started){
        this.scene.pause(); // Pause the scene immediately after creation
    
        // Notify React that the question modal should open
        if (this.game.openQuestion) {
          this.game.openQuestion(); // Call the function passed from React to open the modal
          this.started=true;
        }
      }
    
    
        
    }
    resetGameOptions() {
        this.gameOptions = { ...gameOptions };
    }
    toggleSound() {
        // Toggle the sound state
        this.soundOn = !this.soundOn;

        // Update the sound button icon
        this.soundButton.setTexture(this.soundOn ? 'sound' : 'silent',);

        // Mute or unmute all sounds in the game
        this.sound.mute = !this.soundOn;
    }
    playSound(sound) {
        // Play sound only if sound is enabled
        if (this.soundOn) {
            sound.play();
        }
    }

    spawnItem() {
        // Define the maximum number of active items allowed
        const maxActiveItems = 50; // Adjust this value as needed
    
        // Get the current number of active items
        const currentActiveItems = this.itemGroup.getLength();
    
        // Check if we can spawn a new item
        if (currentActiveItems < maxActiveItems) {
            // Get the most recent platform's Y position
            const platform = this.platformManager.platformGroup.getFirstAlive();
    
            // Ensure there's an active platform
            if (platform) {
                // Set item to spawn slightly above the platform, within jumping range
                const platformY = platform.y;
    
                // Define a reasonable offset above the platform (adjust based on your jump height)
                const minItemOffset = -100; // Minimum 100 pixels above the platform
                const maxItemOffset = -300; // Maximum 300 pixels above the platform
    
                // Randomize the Y position between the min and max offsets above the platform
                const spawnY = Phaser.Math.Between(platformY + maxItemOffset, platformY + minItemOffset);
    
                // Get the current platform speed from the game options
                const platformSpeed = this.gameOptions.platformStartSpeed * this.gameOptions.playerSpeed;
    
                // Define spawn chances for each item type using an object
                const spawnChances = {
                    pizza: 0.6,
                    choco: 0.2,
                    iceCream: 0.06,
                    strawberry: 0.03,
                    goldenApple: 0.1,
                };
    
                // Calculate total weight
                const totalWeight = Object.values(spawnChances).reduce((acc, chance) => acc + chance, 0);
    
                // Generate a random number to determine which item to spawn
                const randomNum = Math.random() * totalWeight;
    
                // Find the item type based on the random number
                let cumulativeWeight = 0;
                let itemType = null;
    
                for (const [key, chance] of Object.entries(spawnChances)) {
                    cumulativeWeight += chance;
                    if (randomNum < cumulativeWeight) {
                        itemType = key; // Get the item type (e.g., 'choco')
                        break;
                    }
                }
                if (itemType === 'goldenApple' && this.superSpawn) {
                    itemType='pizza'
                }
    
                let item;
                // Create a new instance based on the random item type
                switch (itemType) {
                    case 'pizza':
                        item = new Pizza(this, this.game.config.width, spawnY, platformSpeed);
                        break;
                    case 'choco':
                        item = new Choco(this, this.game.config.width, spawnY, platformSpeed);
                        break;
                    case 'iceCream':
                        item = new IceCream(this, this.game.config.width, spawnY, platformSpeed);
                        break;
                    case 'goldenApple':
                        item = new GoldenApple(this, this.game.config.width, spawnY, platformSpeed);
                        break;
                    case 'strawberry':
                        item = new Strawberry(this, this.game.config.width, spawnY, platformSpeed);
                        break;
                }
    
                // Set item properties and add it to the active group
                if (item) {
                    item.setPosition(this.game.config.width, spawnY);
                    item.setActive(true);
                    item.setVisible(true);
                    item.body.setVelocityX(-platformSpeed); // Set item velocity to match platform speed
                    this.itemGroup.add(item); // Add to the active group
                }
            }
        }
    }
        updateItemSpeeds(newSpeed) {
        this.itemGroup.getChildren().forEach((item) => {
            item.body.setVelocityX(-newSpeed); // Update each item's velocity to match the new speed
        });
    }    
    update() {
        // Update the position of each background layer instance
        this.backgroundLayers.forEach((layer) => {
            if (layer.texture.key !== 'sky') { 
                layer.x -= 3 * layer.speed * this.gameOptions.playerSpeed; 
                if (layer.x <= -this.cameras.main.width) {
                    layer.x += this.cameras.main.width * 2; 
                }
            }
        });

        // Game over condition
        if (this.player.y > this.sys.game.config.height) {
            this.scene.pause(); // Pause the scene immediately after creation
            this.game.reactSetScore(this.score)
            // Notify React that the question modal should open
            if (this.game.openQuestion) {
              this.game.openQuestion(); // Call the function passed from React to open the modal
              this.started=true;
            }    
        }

        // Keep player position on the screen
        this.player.x = gameOptions.playerStartPosition;

        // Recycling platforms
        this.platformManager.recyclePlatforms();

        // Adding new platforms
        this.platformManager.addNewPlatforms();

        // Collecting pizzas
        // this.physics.add.overlap(this.player, this.pizzaGroup, (player, pizza) => {
        //     this.player.collect(pizza); // Let the player class handle pizza collection
        // }, null, this);
        this.physics.add.overlap(this.player, this.itemGroup, (player, item) => {
            this.player.collect(item); // Let the player class handle pizza collection
        }, null, this);
        
        // Update pizzas
        this.itemGroup.getChildren().forEach((item) => {
            if (item.x < -item.displayWidth / 2) {
                this.itemGroup.killAndHide(item);
                this.itemGroup.remove(item);
                item.destroy();
            }
        });

        // **Calculate Score by Distance**
        // Increment distance based on the platform speed
        const distanceIncrement = (this.gameOptions.platformStartSpeed*this.gameOptions.playerSpeed)/ 300; // Adjust the divisor to control score increment
        // this.distanceTraveled += distanceIncrement;
        // Update the score based on distance
        this.score += Math.floor(distanceIncrement); 
        
        // Update the score text display
        this.scoreText.setText('Score: ' + this.score.toLocaleString());

        // Deplete energy with distance or time
        this.currentEnergy -= this.energyDepletionRate;

        // Ensure the energy bar doesn't go below 0
        this.currentEnergy = Phaser.Math.Clamp(this.currentEnergy, 0, this.maxEnergy);

        // Redraw the energy bar with the updated energy value
        this.energyBar.clear();
        this.energyBar.fillStyle(0xE6900D, 1); // Orange color (RGB 230, 144, 13)
        this.energyBar.fillRect(16, 60, this.currentEnergy, 15); // Redraw energy bar based on current energy

        // Handle when energy depletes (game over, slow down player, etc.)
        if (this.currentEnergy <= 0) {
            this.handleEnergyDepletion();
        }


    }
    handleEnergyDepletion() {
        this.scene.pause(); // Pause the scene immediately after creation
        this.game.reactSetScore(this.score)
        // Notify React that the question modal should open
        if (this.game.openQuestion) {
          this.game.openQuestion(); // Call the function passed from React to open the modal
          this.started=true;
        }
    }
    
}
