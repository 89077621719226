import Phaser from 'phaser';
import Pizza from './Pizza';
import IceCream from './IceCream';
import Choco from './Choco';
import GoldenApple from './GoldenApple';
import Strawberry from './Strawberry';

class Player extends Phaser.Physics.Arcade.Sprite {
    constructor(scene, x, y) {
        super(scene, x, y, 'player'); // Assuming you have the player sprite loaded

        // Add the sprite to the scene
        scene.add.existing(this);
        scene.physics.add.existing(this);

        this.setGravityY(scene.gameOptions.playerGravity);
        this.setScale(2); // Scale the player if needed

        // Properties for jump logic
        this.playerJumps = 0; // Number of jumps made by the player
        this.maxJumps = scene.gameOptions.jumps; // Maximum allowed jumps
        this.isJumping = false; // Track if the player is jumping
        this.isRunning = true; // Track if the player is running
        
        // Load the jump sound from the scene
        this.jumpSound = scene.sound.add('jumpSound'); // Add the jump sound
        this.collectSound = scene.sound.add('collectSound'); // Add the jump sound

        // Set up animations
        this.setupAnimations(scene);
        
        // Start with the running animation
        this.play('run');

        // Set up collisions
        scene.physics.world.on('worldbounds', (body) => {
            if (body.gameObject === this) {
                this.crash(body);
            }
        });

        // Input event
        this.setupInput(scene);
    }

    setupAnimations(scene) {
        // Check and create animations only if they don't exist
        if (!scene.anims.exists('run')) {
            scene.anims.create({
                key: 'run',
                frames: scene.anims.generateFrameNumbers('player', { start: 0, end: 5 }),
                frameRate: 10,
                repeat: -1
            });
        }

        if (!scene.anims.exists('jump')) {
            scene.anims.create({
                key: 'jump',
                frames: scene.anims.generateFrameNumbers('playerJump', { start: 0, end: 7 }), // Assuming your jump sprites are 0-7
                frameRate: 10,
                repeat: 0 // Play the jump animation once
            });
        }
    }

    setupInput(scene) {
        // Set up input event listeners
        scene.input.on('pointerdown', this.jump, this);
        scene.input.keyboard.on('keydown-SPACE', this.jump, this);
    }

    jump() {
        // Allow jump if either on the ground or playerJumps is less than maxJumps
        if (this.body.touching.down || (this.playerJumps > 0 && this.playerJumps < this.maxJumps)) {
            if (this.body.touching.down) {
                this.playerJumps = 0; // Reset jump count when on the ground
            }
            // Play the jump sound
            this.jumpSound.play(); // Play the sound when jumping

            // Apply upward force to jump
            this.setVelocityY(-this.scene.gameOptions.jumpForce); // Apply upward force
            this.playerJumps++; // Increment jump count with each jump
    
            // Play jump animation and stop running animation
            this.play('jump'); // Start jump animation
            this.isRunning = false; // Player is not running during the jump
        }
    }
        
    preUpdate(time, delta) {
        super.preUpdate(time, delta);

        // Check if the player has landed
        if (this.body.touching.down) {
            if (!this.isRunning) {
                this.play('run'); // Switch back to running animation
                this.isRunning = true;
            }
        }
    }
    
    collect(item) {
        // Apply different actions based on the item type
        this.collectSound.play(); // Play the sound when jumping
        if (item instanceof Pizza) {
            this.handlePizzaCollection(item);
        } else if (item instanceof IceCream) {
            this.handleIceCreamCollection(item);
        } else if (item instanceof Choco) {
            this.handleChocoCollection(item);
        } else if (item instanceof GoldenApple) {
            this.handleGoldenAppleCollection(item);
        } else if (item instanceof Strawberry) {
            this.handleStrawberryCollection(item);
        }

        // Recycle the item by killing and hiding it instead of destroying
        this.scene.itemGroup.killAndHide(item); // Hide and deactivate the item
        this.scene.itemGroup.remove(item); // Remove it from the active group
        item.destroy();
    }
    
    handlePizzaCollection(item) {
        this.scene.score += 100;
        this.createFloatingText(item, '+100');
    }
    
        
    handleChocoCollection(item) {
        this.scene.score += 500;
        this.createFloatingText(item, '+500');
    }

    handleIceCreamCollection(item) {
        this.scene.score += 1000;
        this.createFloatingText(item, '+1000');
    }

    handleStrawberryCollection(item) {
        // Check if the scene exists before proceeding
        if (!this.scene || !this.scene.gameOptions) {
            console.error('Scene or gameOptions is undefined');
            return; // Exit early if scene or gameOptions is undefined
        }
    
        this.speedBoost = true;
        this.maxJumps = 5;
        this.scene.gameOptions.playerSpeed = 2;
    
        // Update the speeds of all currently spawned platforms
        this.scene.platformManager.updatePlatformSpeeds(this.scene.gameOptions.platformStartSpeed * this.scene.gameOptions.playerSpeed);
        this.scene.updateItemSpeeds(this.scene.gameOptions.platformStartSpeed * this.scene.gameOptions.playerSpeed);
        this.createFloatingText(item, '+ Superboost!');
    
        if (!this.scene.superSpawn) {
            this.scene.gameOptions.itemSpawnRate = 1000; // Update the spawn rate
    
            // Stop the existing spawn event if it exists
            if (this.scene.spawnEvent) {
                this.scene.spawnEvent.remove(false); // Remove the existing event
            }
    
            // Create a new spawn event with the updated spawn rate
            this.scene.spawnEvent = this.scene.time.addEvent({
                delay: this.scene.gameOptions.itemSpawnRate,
                callback: this.scene.spawnItem,
                callbackScope: this.scene,
                loop: true
            });
        }
    
        // If there's already an active speed boost, clear the previous timeout
        if (this.speedBoostTimeout) {
            clearTimeout(this.speedBoostTimeout); // Clear the previous timeout
        }
    
        // Extend the boost duration by an additional 5000 ms (5 seconds)
        const boostDuration = 5000; // Duration for the speed boost
        this.speedBoostTimeout = setTimeout(() => {
            // Check again if the scene exists before resetting the speed
            if (!this.scene || !this.scene.gameOptions) {
                console.error('Scene or gameOptions is undefined after timeout');
                return; // Exit early if scene or gameOptions is undefined
            }
    
            this.scene.gameOptions.playerSpeed = 1;
            this.maxJumps = 2;
            this.scene.platformManager.updatePlatformSpeeds(this.scene.gameOptions.platformStartSpeed * this.scene.gameOptions.playerSpeed);
            this.scene.updateItemSpeeds(this.scene.gameOptions.platformStartSpeed * this.scene.gameOptions.playerSpeed);
            this.speedBoost = false;
    
            if (!this.scene.superSpawn) {
                this.scene.gameOptions.itemSpawnRate = 2000; // Reset spawn rate
                // Stop the faster spawn event and return to the normal spawn rate
                if (this.scene.spawnEvent) {
                    this.scene.spawnEvent.remove(false); // Remove the existing event
                }
    
                // Create a new spawn event with the normal spawn rate
                this.scene.spawnEvent = this.scene.time.addEvent({
                    delay: this.scene.gameOptions.itemSpawnRate,
                    callback: this.scene.spawnItem,
                    callbackScope: this.scene,
                    loop: true
                });
            }
        }, boostDuration);
    }
    
    handleGoldenAppleCollection(item) {
        // Check if the super spawn effect is already active
            if(this.scene.currentEnergy < this.scene.maxEnergy){
                this.scene.currentEnergy += 80;
                if (this.scene.currentEnergy > this.scene.maxEnergy){
                    this.scene.currentEnergy = this.scene.maxEnergy;
                }
            }
            this.scene.superSpawn = true; // Set super spawn to true
            this.createFloatingText(item, '+ SuperSpawn'); // Create floating text effect
            this.scene.gameOptions.itemSpawnRate = 200; // Update the spawn rate
    
            // Stop the existing spawn event if it exists
            if (this.scene.spawnEvent) {
                this.scene.spawnEvent.remove(false); // Remove the existing event
            }
    
            // Create a new spawn event with the updated spawn rate
            this.scene.spawnEvent = this.scene.time.addEvent({
                delay: this.scene.gameOptions.itemSpawnRate,
                callback: this.scene.spawnItem,
                callbackScope: this.scene,
                loop: true
            });
    
            this.setTint(0xffff00); // Change color to indicate invincibility
    
            // Reset tint and spawn rate after 5 seconds
            if (this.superSpawnTimeout) {
                this.superSpawnTimeout.remove(false); // Clear previous timeout
            }
    
            this.superSpawnTimeout = this.scene.time.delayedCall(5000, () => {
                this.clearTint(); // Clear the tint effect
                this.scene.gameOptions.itemSpawnRate = 2000; // Reset to normal spawn rate
                this.scene.superSpawn = false; // Reset super spawn status
    
                // Stop the faster spawn event and return to the normal spawn rate
                if (this.scene.spawnEvent) {
                    this.scene.spawnEvent.remove(false); // Remove the existing event
                }
                this.scene.spawnEvent = this.scene.time.addEvent({
                    delay: this.scene.gameOptions.itemSpawnRate,
                    callback: this.scene.spawnItem,
                    callbackScope: this.scene,
                    loop: true
                });
            });
    }
            
    createFloatingText(item, text) {
        const floatingText = this.scene.add.text(item.x, item.y, text, {
            font: '32px Arial',
            fill: 'rgba(255, 255, 255, 1)', // White color
            backgroundColor: 'rgba(0, 0, 0, 0)', // Transparent background
            stroke: '#000000', // Black stroke
            strokeThickness: 2, // Stroke thickness
        }).setOrigin(0.5, 0.5); // Center the text

        // Animate the floating text
        this.scene.tweens.add({
            targets: floatingText,
            y: floatingText.y - 50, // Move it up
            alpha: 0, // Fade out
            duration: 1000, // Duration of the animation
            onComplete: () => {
                floatingText.destroy(); // Destroy the text once done
            }
        });
    }
        
    crash() {
        console.log('player crashed');
        this.setVelocity(0);
        this.setAngularVelocity(0);
        this.scene.input.off('pointerdown', this.jump, this); // Disable jumping
        this.scene.input.keyboard.off('keydown-SPACE', this.jump, this); // Disable spacebar input
        this.scene.scene.pause(); // Pause the game
        this.scene.game.openQuestion(); // Call your game over or next question logic
    }
}

export default Player;
