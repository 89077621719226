import Phaser from 'phaser';
import sound from '../assets/sound.png'; 
import silent from '../assets/silent.png';
import aPieceOfPicture from '../assets/Sounds/A-Piece-Of-Picture.ogg';
import aPieceOfPictureMp3 from '../assets/Sounds/A-Piece-Of-Picture.wav';

export default class MainMenuState extends Phaser.Scene {
  constructor() {
    super({ key: 'MainMenuState' });
    this.soundOn = true; // Track if the sound is on
  }

  init() {
    // Responsive scaling
    this.scale.scaleMode = Phaser.Scale.FIT;
    this.scale.autoCenter = Phaser.Scale.CENTER_BOTH;
  }

  preload() {
    // Load the sound toggle icons
    this.load.image('sound', sound);
    this.load.image('silent', silent);

    // Load background music
    this.load.audio('aPieceOfPicture', [aPieceOfPicture,aPieceOfPictureMp3]);
  }

  create() {
    // Unlock audio on iOS devices
    this.input.once('pointerdown', () => {
      if (this.sound.context.state === 'suspended') {
        this.sound.context.resume();
      }
    });

    // Create a "Click or Press Space to Start" button in the center
    const startButton = this.add.text(600, 360, 'Click or Press Space to Start', {
      fontSize: '32px',
      fill: '#fff'
    }).setOrigin(0.5);

    // Set up the click event to start the game
    startButton.setInteractive();
    startButton.on('pointerdown', () => {
      // Start background music only after user interaction
      if (!this.bgMusic) {
        this.bgMusic = this.sound.add('aPieceOfPicture', { loop: true, volume: 0.3 });
        this.bgMusic.play();
      }
      this.scene.start('GameState'); // Start the GameState
    });

    // Add a keyboard event to start the game with the spacebar
    this.input.keyboard.on('keydown-SPACE', () => {
      this.scene.start('GameState'); // Start the GameState when space is pressed
    });

    // Create the sound toggle button in the top-right corner
    this.soundButton = this.add.image(1200, 40, 'sound').setInteractive(); // Position the button
    this.soundButton.setScale(0.7); // Scale down the size if needed

    // Add click event to toggle sound
    this.soundButton.on('pointerdown', () => {
      this.toggleSound();
    });
  }

  toggleSound() {
    if (this.soundOn) {
      this.soundButton.setTexture('silent'); // Change to silent icon
      this.sound.mute = true; // Mute all game sound including background music
    } else {
      this.soundButton.setTexture('sound'); // Change to sound icon
      this.sound.mute = false; // Unmute all game sound including background music
    }
    this.soundOn = !this.soundOn; // Toggle the sound state
  }
}
