import React from "react";
import "./style.css"
import { useNavigate} from "react-router-dom";
import { useState, useRef, useContext,useCallback, useEffect } from "react";
import API from "../../utils/API"
import { AuthContext } from '../AuthContext/AuthContext';


export function NavUser() {
  const { logout } = useContext(AuthContext);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);
  const navbarRef = useRef(null);
  useEffect(() => {
    const handleScroll = () => {
    };
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 991);
    };

    window.addEventListener('resize', handleResize);
    window.addEventListener('scroll', handleScroll);
    
    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
  };
  }, []);

  const onSignOut = e =>{
    // setCurrentPreviewImage("");
    // setFilesChange([]);
    API.signOut().then(res=>{
      if (res.data.success) {
        // Perform frontend logout
        logout();
        // Redirect using the frontend
        window.location = "/";
      }
      }).catch(err=>{
      console.log(err);
    })
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-black">
    <a className="grow-small navbar-brand titan-one-regular" href="/dashboard">Kooblo.io</a>
   <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      {isMobile ? 
            <div className="collapse navbar-collapse" id="navbarNav">

                <ul className="navbar-nav mx-auto">
                  <li className="nav-item">
                    <a className="nav-link" title="dashboard" href="/dashboard">Home</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/explore">Play</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/dashboard/create">Create</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/join">Join</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/buzzer">Buzzer</a>
                  </li>
                </ul>
                <button className="btn btn-rounded nav-btn-light-purple navbar-btn m-0" onClick={e=> onSignOut(e)}>Sign Out</button>    
                </div>
        : 
        <div className="collapse navbar-collapse" id="navbarNav">

            <ul className="navbar-nav mx-auto">
              <li className="nav-item ">
                <a className="nav-link nav-route" title="Dashboard" href="/dashboard"><i className="fa-solid fa-house"></i>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-route" title="Explore" href="/explore"><i className="fa-solid fa-gamepad"></i></a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-route" title="Create" href="/dashboard/create"><i className="fa-regular fa-square-plus"></i></a>
              </li>
            </ul>
            <div className="auth-buttons">
            <div className="btn-group">
              <button type="button" className="btn nav-btn-light-orange dropdown-toggle rounded-pill navbar-btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Join a Game
              </button>
              <div className="dropdown-menu titan-one-regular">
                <a className="dropdown-item" href="/join">Party game</a>
                <a className="dropdown-item" href="/buzzer">Buzzer</a>
              </div>
            </div>
            <button className="btn btn-rounded nav-btn-light-purple navbar-btn" onClick={e=> onSignOut(e)}>Sign Out</button>    
            </div>
      </div>

        }
    </nav>
  
  );
}

export function Nav() {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 991);
  const navbarRef = useRef(null);
  const [isSticky, setIsSticky] = useState(false);

    useEffect(() => {
      const handleScroll = () => {
        // if (navbarRef.current) {
        //   const sticky = navbarRef.current.offsetTop;
        //   if (window.pageYOffset >= sticky) {
        //     setIsSticky(true);
        //   } else {
        //     setIsSticky(false);
        //   }
        // }
      };
      const handleResize = () => {
        setIsMobile(window.innerWidth <= 991);
      };

      window.addEventListener('resize', handleResize);
      window.addEventListener('scroll', handleScroll);
      
      return () => {
        window.removeEventListener('scroll', handleScroll);
        window.removeEventListener('resize', handleResize);
    };
    }, []);

  return (
    <nav ref={navbarRef} className={"navbar navbar-expand-lg navbar-dark bg-black " + (isSticky ? 'navbar sticky' : 'navbar')} >
    <a className="grow-small navbar-brand titan-one-regular" href="/">Kooblo.io</a>
   <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
        {isMobile ? 
            <div className="collapse navbar-collapse" id="navbarNav">

                <ul className="navbar-nav mx-auto">
                  <li className="nav-item">
                    <a className="nav-link" title="dashboard" href="/dashboard">Home</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/explore">Play</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/dashboard/create">Create</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/join">Join</a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" href="/buzzer">Buzzer</a>
                  </li>
                </ul>
                <a className="btn   btn-rounded nav-btn-light-blue navbar-btn" href="/login">Login</a>
                <a className="btn  btn-rounded nav-btn-light-purple navbar-btn" href="/signup">Sign Up</a>    
            </div>
        : 
        <div className="collapse navbar-collapse" id="navbarNav">

            <ul className="navbar-nav mx-auto">
              <li className="nav-item ">
                <a className="nav-link nav-route" title="Dashboard" href="/dashboard"><i className="fa-solid fa-house"></i>
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-route" title="Explore" href="/explore"><i className="fa-solid fa-gamepad"></i></a>
              </li>
              <li className="nav-item">
                <a className="nav-link nav-route" title="Create" href="/dashboard/create"><i className="fa-regular fa-square-plus"></i></a>
              </li>
            </ul>
            <div className="auth-buttons">
            <div className="btn-group">
              <button type="button" className="btn nav-btn-light-orange dropdown-toggle rounded-pill navbar-btn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Join a Game
              </button>
              <div className="dropdown-menu titan-one-regular">
                <a className="dropdown-item" href="/join">Party game</a>
                <a className="dropdown-item" href="/buzzer">Buzzer</a>
              </div>
            </div>
            <a className="btn  btn-rounded nav-btn-light-blue navbar-btn" href="/login">Login</a>
            <a className="btn btn-rounded nav-btn-light-purple navbar-btn" href="/signup">Sign Up</a>    
          </div>
      </div>

        }
    </nav>

  );
}